import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISSE extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Software Engineering (CSCI-RTSE)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
            Topics of interest include, but are not limited to the following: <br/> <br/>
              <div>
              - Software Architectures <br/>
              - Software Design and Design Patterns <br/>
              - Architectural Analysis and Verifications Methods <br/>
              - Enterprise Software, Middleware, and Tools <br/>
              - Software Reliability, Safety and Security Methods <br/>
              - Software Reuse and Component-Based Software Engineering <br/>
              - UML/MDA and AADL <br/>
              - Agile Software Engineering and Development <br/>
              - Object Oriented Technology (Design and Analysis) <br/>
              - Software Metrics <br/>
              - Software Assurance and Dependability <br/>
              - Reverse and Architectural Recovery Methods <br/>
              - Domain Specific Software Engineering <br/>
              - Aerospace Software and System Engineering <br/>
              - Survivable Systems <br/>
              - Engineering of Safety/Mission Critical Systems <br/>
              - Software Testing, Evaluation and Analysis Technologies <br/>
              - Workflow Management - Computer Supported Cooperative Work (CSCW) <br/>
              - Project Management Issues <br/>
              - Real-time Embedded Software Engineering <br/>
              - Theoretic Approaches (Formal Methods, Graph, ...) <br/>
              - Domain Modeling and Meta-modeling <br/>
              - Software Maintenance and Evolution <br/>
              - Component Based Software Engineering <br/>
              - Software Engineering Standards and Guidelines <br/>
              - Intelligent CASE Tools and Eclipse Plug-ins Issues <br/>
              - Usability Engineering <br/>
              - Novel Software Tools and Environments <br/>
              - Pervasive Software Engineering <br/>
              - Requirement Engineering and Processes <br/>
              - Critical and Embedded Software Design <br/>
              - Service Oriented Software Architectures <br/>
              - Service Oriented Requirements Engineering <br/>
              - Middleware for Service Based Systems <br/>
              - Software Product Lines <br/>
              - Semantic Web <br/>
              - Human Computer Interaction and Usability Engineering <br/>
              - Model Based Software Engineering <br/>
              - Aspect Oriented Software Engineering <br/>
              - Agent Architectures, Ontologies, Languages and Protocols <br/>
              - Multi-Agent Systems, Mobile Agents <br/>
              - E-Commerce Solutions and Applications <br/>
              - Programming Languages and Compilers <br/>
              - Autonomic Computing and Adaptive Systems <br/>
              - Measurement and Empirical Software Engineering <br/>
              - Tutoring, Documentation Systems <br/>
              - Web-Based Tools, Applications and Environment <br/>
              - Web and Text Mining <br/>
              - Case Studies and Emerging Technologies
              </div>

              
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISSE;