import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Jobs extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Career, Job, & Education Fair</h4>
            <div className="par">
            The CSCI 2024 conference plans to host a “Job & Education Fair” to be held 	concurrently with the conference (December 11-13, 2024). This annual strategic event 	provides the conference attendees and participants with opportunities to connect with 	employers and educators and learn more about career options. Corporations and 	Universities can exhibit their products, services, and degree programs during the event.
            <br/><br/>
            Interested parties should contact cs@american-cse.org.
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

export default Jobs;