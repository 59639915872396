

//actions...

export const alertMess = (obj) => {
    return dispatch => {
        if(obj.isVis && !obj.error){
            setTimeout(() => {
                dispatch(alertMess({isVis: false, message:"", error:true}));
              }, 5000);    
        }
        dispatch({
            type: 'ALERT',
            payload: {
                isVis: obj.isVis,
                message: obj.message,
                error: obj.error
            }
        })
    };
}

export const modalsAction = (obj) => {
    return {
        type: "RESIZE",
        payload: obj
    }
}

export const resizeAction = (obj) => {
    return {
        type: "RESIZE",
        payload: obj
    }
}

export const Spinner_Action = (status) => {
    return {
        type: "SPINNER",
        payload: {
            show: status
        }
    }
  }