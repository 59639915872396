import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class PapC_6 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
=

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Full/Regular Research Papers (RRP) Category</h4>
            <div className="par">
            <h6>PRESENTATION IN FORMAL SESSIONS AND 6-PAGE PUBLICATION IN THE IEEE CPS PROCEEDINGS</h6>
            <br/>
            Accepted papers in Full/Regular Research Papers (RRP) category will be presented in Formal Sessions.
              <br/><br/>
              Each presentation will be 20 minutes long. Please bring your PowerPoint presentation on a CD and/or on standard USB Flash Memory. You will be presenting your PowerPoint from a provided conference Laptop and LCD Projector. The PowerPoint Viewer and Adobe Acrobat Reader will be available on all the Laptop computers. You are advised to copy your presentation to the laptop only at the beginning of your session. The conference administrator will remove all presentations after the conclusion of the last session each day.
              <br/><br/>
              The information about the time and the date of your presentation will be emailed to you as soon as it becomes available . Each paper/presentation will be included in a relevant technical session - authors who will be presenting papers are expected to introduce themselves to the chair of the session approximately 5 minutes before the session starts (names of session chairs and conference room numbers will be emailed to you).
              <br/><br/>
              Authors of papers in this category (RRP), who feel that they have language difficulties, might consider requesting their papers be moved to Poster category - Poster Papers will be presented in Poster/Discussion sessions; i.e., in an informal mode. Such requests should be sent to The Steering Committee by email at cs@american-cse.org.
              <br/><br/>
              Please note that you are required to upload an electronic copy of your Full Camera-ready Paper to the publication website before the announced deadline.  The URL to the website is included in the notification of acceptance that has been emailed to the authors of the accepted papers.
              <br/><br/>
              Accepted papers in this category will be included in the conference proceedings. Final Camera-Ready papers should not exceed 6 IEEE style pages (double-column format). For instructions regarding the preparation of the final Camera-Ready papers, refer to the notification that was emailed to you.
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PapC_6;