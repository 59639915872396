import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISOT extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Internet of Things & Internet of Everything (CSCI-RTOT)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
            Topics of interest include, but are not limited to the following: <br/> <br/>
              <div>
              - Intelligent Systems for IoT <br/>
              - Environmental Monitoring <br/>
              - Energy Aware Systems and Efficiency <br/>
              - Machine to Machine/Device Communications <br/>
              - Network Design and Architecture <br/>
              - Networking and Communication Protocols <br/>
              - Wireless Systems and Applications <br/>
              - Infrastructure Management <br/>
              - IoT and e-Government, e-Commerce, e-Science and Novel Technologies <br/>
              - IoT and Big Data <br/>
              - Home Automation <br/>
              - Software Architecture, Middleware, and Frameworks <br/>
              - Services Computing <br/>
              - Health Informatics as a Service <br/>
              - Financial Software as a Service <br/>
              - Business Process as a Service <br/>
              - Natural Science as a Service <br/>
              - Integration as a Service <br/>
              - Large Scale Deployments <br/>
              - Mobile Services <br/>
              - Knowledge Management <br/>
              - Location-awareness <br/>
              - Security and Privacy <br/>
              - Social Implications of IoT <br/>
              - Technological focus for Smart Environments <br/>
              - Smart City Case Studies <br/>
              - Data Analysis and Visualization <br/>
              - Architecture for secure and interactive IoT <br/>
              - Intelligent Infrastructure and Guidance Systems <br/>
              - Sensor Networks, Remote Diagnosis and Development <br/>
              - Transportation Management <br/>
              - Pattern Recognition and Recommendation Systems <br/>
              - Green Computing <br/>
              - Smart City and Novel Methodologies <br/>
              - Data Processing Techniques <br/>
              - Analytics and Statistical Methods <br/>
              - Data Center Enabled Technologies <br/>
              - Sensor, Wireless Technologies and APIs <br/>
              - Networking and Social Networks <br/>
              - IoT in Social Sciences <br/>
              - Education and Learning <br/>
              - Business, Finance and Management <br/>
              - Open data: Issues, Services and Solutions <br/>
              - Earth Science Simulation and Processing <br/>
              - Practical Adoption of IoT and Case Studies <br/>
              - Healthcare Services and Health Informatics <br/>
              - Cloud, Grid and Cluster Computing <br/>
              - Emerging IoT
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISOT;