import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISCW extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Cyber Warfare, Cyber Defense, & Cyber Security (CSCI-RTCW)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Prologue:</h6>
              <div>
              Cyber Warfare adds an additional dimension to warfare. Generally, it
              involves the battlespace use and targeting of computers and networks in
              warfare. Cyber Warfare strategies include both offensive and defensive
              operations pertaining to the threat of cyberattacks, espionage, sabotage,
              disruptions to infrastructures, theft of critical information, and
              publishing deliberate misinformation (fake news and fabricated news)
              spread via traditional media and online social media with the intend to
              mislead in order to disrupt the political processes of nations. The
              methods and technologies utilized in Cyber Warfare can be countered
              using Cyber Defense strategies. Cyber Defense strategies involve the
              heavy and targeted use of Cyber Security. Topics of interest include:
              <br/> <br/>

              - Cyber Warfare Technologies and Strategies <br/>
              - Cyber Defense Technologies and Strategies <br/>
              - Authorization Infrastructures <br/>
              - Autonomous Security Methods <br/>
              - Novel Biometric Methods <br/>
              - Cloud Security <br/>
              - Forensic Technologies <br/>
              - Cryptography and Formal Methods <br/>
              - Trapping Technologies <br/>
              - Data Protection Methods <br/>
              - Security and Reverse Engineering <br/>
              - Vulnerability Analysis <br/>
              - Steganography and Steganalysis <br/>
              - Identity Management <br/>
              - Security Policies <br/>
              - Legislation and Regulatory Approaches for Enhancing Security <br/>
              - Safe Computing <br/>
              - Identification and Prevention of Online Scams <br/>
              - Mobile Protection Techniques <br/>
              - Security Risk Analysis, Management and Trade-offs <br/>
              - Identity, Privacy and Trust Management <br/>
              - Security by Design <br/>
              - Incident Response Methods <br/>
              - Cybercrime and Underground Economy - Identification and Prevention <br/>
              - Detection of Malicious Software <br/>
              - Novel Security Tools <br/>
              - Lifecycle Security Management and Identification of the Weakest Links <br/>
              - Internet Security <br/>
              - Network Security <br/>
              - Computer Security <br/>
              - Prevention of Cyber Attacks on Critical Infrastructures <br/>
              - Methodologies in Reducing National Vulnerability to Cyber Attacks <br/>
              - Methods for Minimizing Damage and Recovery Time from Cyber Attacks <br/>
              - Prevention of Fake and Fabricated News - Media Trust Management <br/>
              - Cyber Warfare and Attack Prevention during Elections <br/>
              - Secure Voting Systems <br/>
              - Securing Electric Power Grids + Smart Grids <br/>
              - Securing Communication Systems <br/>
              - Cyber Counter-intelligence <br/>
              - Tools in Cyber Warfare and Cyber Defense Arsenal <br/>
              - Innovative Tools for Cyber Defense Technologies <br/>
              - Cyber Weapons <br/>
              - Cyber Space and Security <br/>
              - Threats from Social Media <br/>
              - Crime and Terrorism <br/>
              - IT and Countering Violent Extremism <br/>
              - Global Security <br/>
              - Data Analytics and Cyber Warfare <br/>
              - Prevention of Bad Data Injection <br/>
              - Advanced Persistent Threat (APT) and Prevention <br/>
              - Data Exfiltration <br/>
              - Tools for Countering Attacks by Viruses, Worms, Malware, Phishing, SQL Injection Attack, Cross-Site Scripting, Session Hijacking, Trojan Horses, Rootkits, Botnets, ... <br/>
              - Security and Social Engineering <br/>
              - Tools for Countering Logic Bomb, Time Bomb, Distributed Denial-of-Service Attack, ... <br/>
              - Methodologies to Address System and Data Vulnerabilities <br/>
              - Novel Patching Techniques <br/>
              - Next Generation Firewalls <br/>
              - Intrusion Detection Techniques <br/>
              - Terrorism Databases and Resources <br/>
              - Terrorist Networks <br/>
              - Threat Assessment <br/>
              - Cyber Vandalism and Defense <br/>
              - Cyber Security and Terrorism <br/>
              - Counter-terrorism <br/>
              - Cyber Tools and Identification of Propaganda <br/>
              - Security Education and Training
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISCW;