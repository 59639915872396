import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Contact extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Contact</h4>
            <div className="par">
            Questions and inquiries should be sent to: <br/> <br/>
            <b>CSCI 2024 Conference Secretariat</b>   <br/>
            <b>Email:</b> cs@american-cse.org
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Contact;