import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Symps extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h6 className="par_header">CSCI'24 Research Tracks</h6>
            <div className="par">
            The CSCI conference is composed of the following Research Tracks; all
            accepted papers will be included in the proceedings of The 2024
            International Conference on Computational Science and Computational
            Intelligence (CSCI), to be published by CPS.
              <div className="sym_links">
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCS">Research Track on Computational Science (CSCI-RTCS)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCI">Research Track on Computational Intelligence (CSCI-RTCI)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCW">Research Track on Cyber Warfare, Cyber Defense, & Cyber Security (CSCI-RTCW)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTPC">Research Track on Signal & Image Processing, Computer Vision & Pattern Recognition (CSCI-RTPC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTSC">Research Track on Smart Cities and Smart Mobility (CSCI-RTSC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTED">Research Track on Education (CSCI-RTED) - includes accreditation, such as ABET</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTHI">Research Track on Health Informatics and Medical Systems (CSCI-RTHI)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTMC">Research Track on Mobile Computing, Wireless Networks, & Security (CSCI-RTMC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTSE">Research Track on Software Engineering (CSCI-RTSE)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTOT">Research Track on Internet of Things & Internet of Everything (CSCI-RTOT)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTBD">Research Track on Big Data and Data Science (CSCI-RTBD)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTAI">Research Track on Artificial Intelligence (CSCI-RTAI)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTNA">Research Track on Social Network Analysis, Social Media, & Mining (CSCI-RTNA)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCC">Research Track on Cloud Computing and Data Centers (CSCI-RTCC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCB">Research Track on Computational Biology (CSCI-RTCB)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTPD">Research Track on Parallel & Distributed Computing (CSCI-RTPD)</Link><br/>
              </div>
              
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Symps;