import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISSC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Smart Cities & Smart Mobility (CSCI-RTSC)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Prologue:</h6>
              <div>
              A smart city utilizes different types and different kinds of electronic
   data collection sensors to supply information which is used to manage
   assets and optimize the use of resources for the good of citizens.
   Typically, data is collected from devices and assets that is analyzed
   to monitor and manage transportation systems, power grids, water supply
   networks, waste management, law enforcement, information systems,
   schools, libraries, hospitals, and other services. An important component
   of a smart city infrastructure is smart mobility. Smart Mobility focuses
   mainly on challenges that relate to transportation. Some consider a
   successful Smart Mobility framework to offer modes of transportation
   alongside or even instead of personal gas-powered vehicles. Considering
   the above broad description, the topical scope of CSCI-RTSC is given below.
   The list of topics is by no means meant to be exhaustive.
              </div>

              <br/>

              <h6> Smart Cities:</h6>
              <div>
              - Algorithms <br/>
              - City Infrastructures <br/>
              - Communication Frameworks <br/>
              - Big Data Analytics <br/>
              - Health care <br/>
              - Security <br/>
              - Internet of Things <br/>
              - Smart Grid <br/>
              - Inclusive and Sharing Cities <br/>
              - Sustainable Buildings <br/>
              - Efficient Energy utilization <br/>
              - Video Monitoring Systems <br/>
              - Automation, Robotics, and Control <br/>
              - Applications <br/>
              </div>

              <br/>


              <h6>Smart Mobility:</h6>
              <div>
              - Methodologies that help keep cities moving <br/>
              - Traffic Management and Engineering <br/>
              - Smart Public Transportation <br/>
              - Self-Driving Vehicles <br/>
              - Communication Logistics <br/>
              - Parking Management Systems <br/>
              - Cyber-Physical systems and Devices <br/>
              - Economy of Smart Mobility <br/>
              - Governance of Smart Mobility <br/>
              - Cloud Computing Utilization <br/>
              - Coordination Strategies
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISSC;