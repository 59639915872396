import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISPD extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Parallel & Distributed Computing (CSCI-RTPD)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Big Data and High-Performance Computing:</h6>
              <div>
              Novel computational methodologies, HPC and scalable algorithms for
     enhancing data quality, models and frameworks for Big Data, graph
     algorithms, computational science, computational intelligence,
     Scalable systems and architectures for Big Data, ...
              </div>

              <br/>

              <h6>Parallel/Distributed Applications:</h6>
              <div>
              Numerical computations/methods, neural networks and fuzzy logic,
     medicine, remote sensing, computer vision, computer graphics and
     virtual reality, parallel/distributed databases, banking, financial
     markets, high-performance computational biology, ...
              </div>

              <br/>


              <h6>Parallel/Distributed Architectures:</h6>
              <div>
              Clusters and parallel systems of various topologies, supercomputers,
     shared memory, distributed memory, general- and special-purpose
     architectures, instructional level parallelism, ...
              </div>
              <br/>



              <h6>Networks and Interconnection Networks:</h6>
              <div>
              Scalable networks, reconfigurable networks, routing issues,
     general-purpose network performance for distributed applications,
     network protocols, internet technology, optical interconnections
     and computing, novel network topologies, ...
              </div>
              <br/>

              <h6>Energy/power-aware Computing:</h6>
              <div>
              Energy-aware resource management, green computing, energy efficiency
     planning, energy-aware technologies, low power virtualization and
     software techniques, low power logic/circuit/process technology
     design, methods and tools for forecasting, estimating, and
     validating power use, ...
              </div>
              <br/>

              <h6>Reliability and Fault-tolerance:</h6>
              <div>
              Software and hardware fault-tolerance (system- & application-level),
     fault diagnosis, fault-tolerance measurement.
              </div>
              <br/>
              <h6>Building Block Processors:</h6>
              <div>
              Applications of processors that can be used as basic building blocks
     for multicomputer systems.
              </div>
              <br/>
              <h6>Real-time and Embedded Systems:</h6>
              <div>
              Small-scale parallel systems for high-performance control, data
     acquisition, and analysis; configuration, routing, scheduling,
     performance guarantees, ...
              </div>
              <br/>
              <h6>Parallel/Distributed Algorithms:</h6>
              <div>
              Algorithms exploiting clusters and general-purpose distributed and
     parallel systems, new vector/pipeline issues, shared memory,
     distributed memory, virtual memory, ...
              </div>
              <br/>
              <h6>Multimedia Communications, Systems, and Applications:</h6>
              <div>
              High-speed networking, multimedia architectures and protocols,
     multimedia applications, quality of service support, operating
     system and networking support, internet tools and applications,
     audio/video delivery over the internet, ...
              </div>
              <br/>
              <h6>Software Tools & Environments for Parallel & Distributed Platforms:</h6>
              <div>
              Operating systems, compilers, languages, debuggers, monitoring
     tools, software engineering on parallel/distributed systems, ...
              </div>
              <br/>
              <h6>High-performance Computing in Computational Science:</h6>
              <div>
              Intra-disciplinary and multi-disciplinary research programs and
     applications, Performance Evaluation and Management, Distributed
     System, Mobile Computation and Communication, Scheduling and
     Resource Management, and Complex Systems.
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISPD;