import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISPC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Signal & Image Processing, Computer Vision & Pattern Recognition (CSCI-RTPC)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Prologue:</h6>
              <div>
              The broad area of Signal and Imaging Science is a field that is
   mainly concerned with the generation, collection, analysis, modification,
   and visualization of signals and images. The field is multidisciplinary
   in that it includes topics that are traditionally covered in computer
   science, physics, mathematics, electrical engineering, AI, psychology and
   information theory where computer science acts as the topical bridge
   between all such diverse areas (for a formal definition of Imaging Science,
   refer to relevant wiki pages.) From the computer science perspective, the
   core of Imaging Science includes the following three intertwined computer
   science fields, namely: Image Processing, Computer Vision, and Pattern
   Recognition. This conference will cover the research trends in these three
   important areas. The list of topics of interest that appears below is not
   meant to be exhaustive.
              </div>

              <br/>

              <h6>Image and Signal Processing:</h6>
              <div>
              Signal Identification; Signal Reconstruction; Spectral Analysis; Statistical
   & Optical Signal Processing; Time-Frequency Signal Analysis; Software Tools
   for Imaging; Image Generation, Acquisition, and Processing; Image-based
   Modeling and Algorithms; Mathematical Morphology; Image Geometry and
   Multi-view Geometry; 3D Imaging; Novel Noise Reduction Algorithms; Image
   Restoration; Enhancement Techniques; Segmentation Techniques; Motion and
   Tracking Algorithms and Applications; Watermarking Methods and Protection +
   Wavelet Methods; Image Data Structures and Databases; Image Compression,
   Coding, and Encryption; Video Analysis; Multi-resolution Imaging Techniques;
   Performance Analysis and Evaluation; Multimedia Systems and Applications;
   and Novel Image Processing Applications.
              </div>

              <br/>


              <h6>Computer Vision:</h6>
              <div>
              Camera Networks and Vision; Sensors and Early Vision; Machine Learning
   Technologies for Vision; Image Feature Extraction; Cognitive & Biologically
   Inspired Vision; Object Recognition; Soft Computing Methods in Image
   Processing and Vision; Stereo Vision; Active and Robot Vision; Face and
   Gesture Recognition; Fuzzy and Neural Techniques in Vision; Medical Image
   Processing and Analysis; Novel Document Image Understanding Techniques;
   Special-purpose Machine Architectures for Vision; Biometric Authentication;
   Novel Vision Application and Case Studies.
              </div>
              <br/>

              <h6>Pattern Recognition:</h6>
              <div>
              Supervised and Un-supervised Classification Algorithms; Clustering
   Techniques; Dimensionality Reduction Methods in Pattern Recognition;
   Symbolic Learning; Ensemble Learning Algorithms; Parsing Algorithms;
   Bayesian Methods in Pattern Recognition and Matching; Statistical Pattern
   Recognition; Invariance in Pattern Recognition; Knowledge-based
   Recognition; Structural and Syntactic Pattern Recognition; Applications
   Including: Security, Medicine, Robotic, GIS, Remote Sensing, Industrial
   Inspection, Nondestructive Evaluation (or NDE), ...; Case studies and
   Emerging technologies.
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISPC;