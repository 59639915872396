import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISBD extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Big Data and Data Science (CSCI-RTBD)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Algorithms for Big Data:</h6>
              <div>
              Data and Information Fusion; Algorithms (including Scalable methods);
              Natural Language Processing; Signal Processing; Simulation and Modeling;
              Data-Intensive Computing; Parallel Algorithms; Testing Methods;
              Multidimensional Big Data; Multi-linear Subspace Learning; Sampling
              Methodologies; and Streaming.
              </div>

              <br/>

              <h6>Big Data Fundamentals:</h6>
              <div>
              Novel Computational Methodologies; Algorithms for Enhancing Data Quality;
              Models and Frameworks for Big Data; Graph Algorithms and Big Data;
              Computational Science; and Computational Intelligence.
              </div>

              <br/>


              <h6>Infrastructures for Big Data & Data Science:</h6>
              <div>
              Cloud Based Infrastructures (applications, storage & computing resources);
   Grid and Stream Computing for Big Data; High Performance Computing,
   Including Parallel & Distributed Processing; Autonomic Computing;
   Cyber-infrastructures and System Architectures; Programming Models and
   Environments to Support Big Data; Software and Tools for Big Data; Big
   Data Open Platforms; Emerging Architectural Frameworks for Big Data;
   Paradigms and Models for Big Data beyond Hadoop/MapReduce, ...
              </div>

              <br/>

              <h6>Big Data & Data Science Management and Frameworks:</h6>
              <div>
              Database and Web Applications; Federated Database Systems; Distributed
   Database Systems; Distributed File Systems; Distributed Storage Systems;
   Knowledge Management and Engineering; Massively Parallel Processing
   (MPP) Databases; Novel Data Models; Data Preservation and Provenance;
   Data Protection Methods; Data Integrity and Privacy Standards and
   Policies; Novel Data Management Methods; Crowdsourcing; Stream Data
   Management; and Scientific Data Management.
              </div>

              <br/>

              <h6>Big Data Search:</h6>
              <div>
              Multimedia and Big Data; Social Networks; Web Search and Information
   Extraction; Scalable Search Architectures; Cleaning Big Data (noise
   reduction), Acquisition & Integration; Visualization Methods for
   Search; Time Series Analysis; Recommendation Systems; and Graph Based
   Search and Similar Technologies.
              </div>

              <br/>

              <h6>Security & Privacy in the Era of Data Science & Big Data:</h6>
              <div>
              Cryptography; Threat Detection Using Big Data Analytics; Privacy
   Threats of Big Data; Privacy Preserving Big Data Collection; Intrusion
   Detection; Socio-economical Aspect of Big Data in the Context of
   Privacy and Security.
              </div>

              <br/>

              <h6>Applications of Big Data:</h6>
              <div>
              Big Data as a Service; Big Data Analytics in e-Government and Society;
   Applications in Science, Engineering, Healthcare, Visualization,
   Business, Education, Security, Humanities, Bioinformatics, Health
   Informatics, Medicine, Finance, Law, Transportation, Retailing,
   Telecommunication, all Search-based applications, ...
              </div>



            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISBD;