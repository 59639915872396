import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Button } from 'react-bootstrap';
import { push, replace } from 'react-router-redux'
import {connect} from 'react-redux';
import axios from "axios"
import conf from '../../_Conf'

class Reg_done extends Component {
  componentWillMount = () =>{
    try {
      let st = this.props.location.state.form
    } catch {
        this.props.replace("/csci2024/registration_start")
    }
    let obj = {
        ...this.props.location.state.result.paymentIntent,
        ...this.props.location.state.form,
        prev_payments: ''
    }
    axios.post(conf.Production? conf.ProductionAddress + 'csci2024/logger/' : 'http://127.0.0.1:5050/' + 'csci2024/logger/',
        obj,
        { headers: { 'Content-Type': 'application/json' } }
        ).then(response => {
            // console.log(response.data)
        }
      )
    // console.log(obj)
  }
  
  
  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Registration - Success!</h4>
            <div className="par">            
                <div style={{
                    margin: "2rem auto",
                    fontSize: "30px",
                    color: "green"
                }}>SUCCESS!</div>
                This is an automated message to confirm that your payment has been submitted. <br/>
                You should receive a confirmation email soon.
                
                <br/><br/>

                <div>
                    <div> <b>Payment ID: </b> {this.props.location.state.result.paymentIntent.id}</div>
                    <div> <b>Amount: </b> ${this.props.location.state.result.paymentIntent.amount / 100}.00</div>
                    <div> <b>Receipt Email: </b>{this.props.location.state.result.paymentIntent.receipt_email}</div>
                    <div> <b>Name: </b> {this.props.location.state.form.fn} {this.props.location.state.form.ln}</div>
                    {this.props.location.state.form.pc >= 1 &&
                    <div>
                      <b>Paper 1: </b> {this.props.location.state.form.p1_id} - {this.props.location.state.form.p1_title}
                    </div>
                    }
                    {this.props.location.state.form.pc >= 2 &&
                    <div>
                      <b>Paper 2: </b> {this.props.location.state.form.p2_id} - {this.props.location.state.form.p2_title}
                    </div>
                    }
                    {this.props.location.state.form.pc >= 3 &&
                    <div>
                      <b>Paper 3: </b> {this.props.location.state.form.p3_id} - {this.props.location.state.form.p3_title}
                    </div>
                    }
                </div>

                <br/>
                <br/>

                <b>Please save/print this page for your records.</b>

                <br/>
                <br/>


                {/* <br/><br/>
                    <div style={{"text-align": "center"}}>
                        <Button style={{margin: "0 auto"}} onClick={()=>{
                            this.props.replace("/csci2024/registration_form")
                        }}>
                                Start
                        </Button>
                    </div> */}
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      replace
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps())(Reg_done);