import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Spinner_Action} from '../../actions';
import { RingLoader, PropagateLoader } from 'react-spinners';


class Spinner extends Component {

  render(){
  return (
    // <div className="r_container">
        <div className="spinner">
            <RingLoader
            sizeUnit={"px"}
            size={150}
            color={'orange'}
            // loading={this.props.Spinner_ActionReducer.show}
            loading={true}
            />
      {/* </div> */}
    </div>
  )
  }
}

const mapStateToProps = state => {
  return {
    Spinner_ActionReducer: state.Spinner_ActionReducer
  }
}

const mapDispatchToProps = () => {
  return {
    Spinner_Action
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(Spinner);
