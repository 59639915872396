import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISHI extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Health Informatics and Medical Systems (CSCI-RTHI)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Prologue:</h6>
              <div>
              Health Informatics and Medical Systems is an important area that is at
              the intersection of information science, computer science, social
              science, behavioral science and health care. This field is mainly
              concerned with the resources, devices, and methods required to optimize
              the acquisition, storage, retrieval, processing and use of information
              in health and biomedicine. Health informatics is driven by computers,
              information and communication systems. It is a multi-disciplinary field
              that covers a wide spectrum of sub-fields. It combines the problem
              solving skills of computer scientists with health care professionals and
              health care givers. The list of topics of interest that appears below is
              by no means exhaustive: <br/> <br/>
              - Information Technologies for Healthcare Delivery and Management <br/>
              - Health Data Acquisition, Management and Visualization <br/>
              - Healthcare Knowledge Management and Decision Support <br/>
              - Healthcare Modeling and Simulation <br/>
              - Data Analytics, Data Mining and Machine Learning <br/>
              - Health Information Systems <br/>
              - Clinical Informatics <br/>
              - Integrated Data Repository <br/>
              - Computational Health Informatics <br/>
              - Community Health Informatics <br/>
              - Informatics for Education & Research in Health and Medicine <br/>
              - Imaging Science <br/>
              - Collaboration tools such as social media, web apps, patient education <br/>
              - Healthcare Communication Networks and Environments <br/>
              - Biotechnology and Pharmaceuticals <br/>
              - Computer-Aided Surgery <br/>
              - Computers in Medical Education <br/>
              - Haptics in Healthcare <br/>
              - Health Care Information Systems <br/>
              - Implant Technology and Systems <br/>
              - Interactive 3-D Models <br/>
              - Medical Physics <br/>
              - Neural, Sensory Systems and Rehabilitation <br/>
              - Patient Diagnosis Methods <br/>
              - Patient Monitoring Systems <br/>
              - Regenerative Medicine <br/>
              - Systems Physiology <br/>
              - Telemedicine <br/>
              - Therapeutic Engineering and Systems
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISHI;