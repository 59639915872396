import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Deadlines extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h5 className="par_header">DEADLINES - EXTENDED</h5>
          <h6 className="par_header">FOR AUTHORS OF ACCEPTED PAPERS:</h6>
            <div className="par">
            Since the books/proceedings will be published after the conference, we have some flexibility with the submission deadlines. Due to numerous requests, the paper submission deadline has been extended as outlined below. Authors who have already submitted their papers in response to the earlier announcement will receive the decision/status report shortly, if they have not yet done so.
            <br /> <br />
          <b>EXTENDED DEADLINES:</b> <br />
          <br />
          WE HAVE A REVOLVING DEADLINE: papers will be refereed using the fast-track evaluation system (FTE) – i.e., referees will be assigned to each paper immediately when a paper is submitted.
          <br /><br />

            - SUBMISSION OF PAPERS (for peer-review evaluation):<br />
            &emsp; <b>November 2, 2024</b> <br /> 
            &emsp; <a target="_blank" href="https://american-cse.org/drafts/">https://american-cse.org/drafts/</a> 
            <br/>            
            <br />

            - NOTIFICATION OF ACCEPTANCE/REJECTION:<br />
            &emsp; <b>REVOLVING DATE</b>

            <br /><br />

            - REGISTRATIONS:<br />
            &emsp; <b>REVOLVING DATE</b> <br />
            &emsp; <a href="https://american-cse.org/csci2024/registration" target="_blank" rel="noopener noreferrer">https://american-cse.org/csci2024/registration</a>
            <br /> &emsp; Authors of accepted papers will find the registration deadline in their notification of acceptance.

            <br /><br />
            - LOCATION AND ROOM RESERVATION: <br />
            &emsp; <b>November 22, 2024</b> <br />
            &emsp;Luxor - an MGM property, Las Vegas, USA <br />
            &emsp;<a href="https://www.american-cse.org/csci2024/venue" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/venue</a>

            
            <br /><br />
            <b>- The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24):</b> <br />
            &emsp; <b>December 11-13, 2024</b> <br />
            &emsp; <a href="https://www.american-cse.org/csci2024/" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/</a> <br />
            {/* &emsp; + all affiliated Research Tracks: <br />
            &emsp; <a href="https://www.american-cse.org/csci2024/topics" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/topics</a> <br /> */}
            
            <br />
            - FINAL PAPERS:<br />
            &emsp; <b>December 31, 2024</b> <br />
            &emsp; As in previous years, the conference proceedings will be published shortly after the event. Authors of accepted papers will receive an email with the PUBLICATION link well in advance.
            
{/* <br /><br /><br /> */}
            {/* <i>(Note: We will probably extend the deadlines as needed – however, the sooner a paper is submitted, the sooner notification can be sent.)</i> */}
            <br /><br />
            </div>

            <h6 className="par_header">FOR NON-AUTHORS & NON-SPEAKER ATTENDEES:</h6>
            <div className="par">
            
            - REGISTRATIONS:<br />
            &emsp; <b>November 25, 2024</b> <br />
            &emsp; <a href="https://american-cse.org/csci2024/registration" target="_blank" rel="noopener noreferrer">https://american-cse.org/csci2024/registration</a>
            <br /> &emsp; Authors of accepted papers will find the registration deadline in their notification of acceptance.
            <br /><br />
            - LOCATION AND ROOM RESERVATION: <br />
            &emsp; <b>November 22, 2024</b> <br />
            &emsp;Luxor - an MGM property, Las Vegas, USA <br />
            &emsp;<a href="https://www.american-cse.org/csci2024/venue" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/venue</a>

            
            <br /><br />
            <b>- The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24):</b> <br />
            &emsp; <b>December 11-13, 2024</b> <br />
            &emsp; <a href="https://www.american-cse.org/csci2024/" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/</a> <br />
            {/* &emsp; + all affiliated Research Tracks: <br />
            &emsp; <a href="https://www.american-cse.org/csci2024/topics" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/topics</a> <br /> */}
            

            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Deadlines;