import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote1 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
            <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>To Trust, or Not to Trust, Who is Making the Decisions:
A Look at the Limits of AI Decision Making
</b> <br/>
            <br />Professor John Murray <br/>
            Academic Dean, Faculty of Business & Technology &<br />
            Professor of Robotics and Autonomous Systems,<br />
            University of Sunderland, UK<br />
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            In this talk we investigate the role of embodiment in shaping user perceptions of trust, fairness, and engagement in AI-driven decision-making. With AI becoming more prevalent in society and touching our lives whether we know or not, we look at the role embodiment plays on user perceptions of trust. We ask the question: “What should be the limits of AI driven decision making?”. Looking at specific scenarios we will challenge the limits of AI engagement, influence and bold actions on human-centred decision making. Fundamentally exploring a secondary question: “Does physical representation through embodiment change human opinion?”. This study provides practical insights for designing AI systems that incorporate physical embodiment and transparency-enhancing features to foster trust and engagement, with implications for various domains, including education, healthcare, and customer service.
            </div>

            <h6 className="par_header">Speaker’s Biography:</h6>
            <div className="par">
            Professor Murray is Academic Dean of the Faculty of Business & Technology at the University of Sunderland, UK, and Professor of Robotics and Autonomous Systems. He has spent much of his academic research career in the area of Human-Robot Interaction, and Ethics and Trust around Artificial Intelligence and Machine Learning systems and has worked on many research projects from FP7 to H2020 and with businesses and organisations including DASA, QinetiQ, UK Home Office, Borderforce, Health organisations including the NHS and many more. He is specifically interested in the acceptance of technology, robotics and AI into society, the social challenges of this and how we design AI and Robotic systems to be socially acceptable. This includes looking at work around human factors, cognitive biases and personality and ethics. He also has interests in security, from computer security (such as ethics, encryption) to border security (through work with the UK Home Office and Border Force) to food security and policy.
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/murray3.jpg" height="300px" alt=""/>
            </div>
            </div>          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote1;