import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PaperS extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Paper Submission</h4>
            <div className="par">
            Submissions are to be uploaded to the submission/evaluation web site portal at: <a href="https://american-cse.org/drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/drafts/</a> <br />
            However, before doing so, please read the instructions below.

                <br /><br />
                All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/> <br />
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). Only about 5% of all journals and conference proceedings achieve the same high level of scientific indexing as CSCI publications. <br /><br />
            INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
<br /><br />
            
                Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
                <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

                
            </div>

            <h6 className="par_header">INSTRUCTIONS FOR SUBMISSION OF PAPERS FOR EVALUATION:</h6>
            <div className="par">
            For the submission of draft papers (ie, papers submitted for evaluation), any reasonable formatting/typesetting is acceptable. Authors of accepted papers will later receive specific typesetting instructions to prepare their final versions. The final papers are expected to use the standard Springer Nature typesetting format ("US letter" standard size): <br /><br />
            WORD template:  <a href="https://www.springer.com/gp/authors-editors/conference-proceedings/editors/word-template/19338734" target="_blank" rel="noopener noreferrer">https://www.springer.com/gp/authors-editors/conference-proceedings/editors/word-template/19338734</a> <br />
            LaTeX template: <a href="https://www.springer.com/gp/computer-science/lncs/new-latex-templates-available/15634678" target="_blank" rel="noopener noreferrer">https://www.springer.com/gp/computer-science/lncs/new-latex-templates-available/15634678</a> <br /><br />

            All submissions must be original and must not have been previously published or currently under consideration for publication elsewhere. <br /><br />

            For categories of papers and page limitations, refer to: <a href="https://www.american-cse.org/csci2024/paper_categories" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/paper_categories</a>
          <br /><br />
          Submissions must be in either MS doc or pdf formats (only one files should be uploaded/submitted for each paper). The first page should include:

                <br/><br/>

                - Title of the paper <br/>
                - Name, affiliation, city, country, and email address of each author (identify the name of the Contact Author) <br/>
                - Abstract (about 100 words) <br/>
                - A maximum of 5 topical keywords that would best represent the work described in the paper <br/>
                - Write the acronym of the most relevant Research Track that this paper is being submitted to; the acronyms are: <br/> <br />
                    <div>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCS">Research Track on Computational Science (CSCI-RTCS)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCI">Research Track on Computational Intelligence (CSCI-RTCI)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCW">Research Track on Cyber Warfare, Cyber Defense, & Cyber Security (CSCI-RTCW)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTPC">Research Track on Signal & Image Processing, Computer Vision & Pattern Recognition (CSCI-RTPC)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTSC">Research Track on Smart Cities and Smart Mobility (CSCI-RTSC)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTED">Research Track on Education (CSCI-RTED) - includes accreditation, such as ABET</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTHI">Research Track on Health Informatics and Medical Systems (CSCI-RTHI)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTMC">Research Track on Mobile Computing, Wireless Networks, & Security (CSCI-RTMC)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTSE">Research Track on Software Engineering (CSCI-RTSE)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTOT">Research Track on Internet of Things & Internet of Everything (CSCI-RTOT)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTBD">Research Track on Big Data and Data Science (CSCI-RTBD)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTAI">Research Track on Artificial Intelligence (CSCI-RTAI)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTNA">Research Track on Social Network Analysis, Social Media, & Mining (CSCI-RTNA)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCC">Research Track on Cloud Computing and Data Centers (CSCI-RTCC)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCB">Research Track on Computational Biology (CSCI-RTCB)</Link><br/>
                    <Icon icon='link' size="1x"/> <Link to="/csci2024/RTPD">Research Track on Parallel & Distributed Computing (CSCI-RTPD)</Link><br/>
                </div>

                <br/>
                For the topical scope of each of the above tracks, refer to: <a href="https://www.american-cse.org/csci2024/topics" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/topics</a>
                <br /><br />
                The actual paper can start from the first page (space permitting)
                <br/> <br/>
                Submissions are to be uploaded to the submission/evaluation web
                site portal at: <a target="_blank" href="https://american-cse.org/drafts/">https://american-cse.org/drafts/</a>

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PaperS;