import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Review extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Paper Review Process</h4>
            <div className="par">
            Each paper will be peer-reviewed by 2 to 4 peers (except for
            papers that are philosophical in nature - such papers would be
            considered for panel discussions.) Confidentiality of submitted
            material will be maintained.  Papers will be evaluated on the
            basis of originality, impact, significance, quality of research,
            quality of writing, and contribution to conference program and
            diversity. Papers whose authors include one or more members of
            the program committee will be evaluated using the double-blinded
            review process. Authors whose papers are not selected for
            publication can submit a rebuttal letter; rebuttals will be
            evaluated by 4 members of the Program Committee.
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Review;