import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';


class Home extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">11th Annual Conf. on Computational Science & Computational Intelligence (CSCI'24)</h4>
            <div className="par">
              The CSCI'24 International Conference invites paper submissions from diverse communities, including researchers from: universities, corporations, government agencies, and standardization bodies. Accepted papers will be published in the conference proceedings by by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>). Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br />
              <br />
              INDEXATION: Scopus; DBLP, EI Engineering Index (Compendex, Inspec databases); Springer Link; Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others. <br /> <br />
              Papers are sought that address solutions to problems in all areas of computational science and computational intelligence. 
              <br></br><br/>
              <b>Computational Science (CS) </b>
              is the study of addressing problems that are  impossible to solve (or difficult to solve) without computers. CS can be  considered to be the bridge between computer science and other sciences. The field is interdisciplinary by nature and includes the use of advanced computing capabilities to understand and solve complex problems. In short, CS is the science of using computers to do science.
              <br/><br/>
              <b>Computational Intelligence (CI) </b>
              is the study of computational methods in ways that exhibit intelligence. These methods adapt to changing environments and changing goals. There is a significant overlap between the fields of CI and Artificial Intelligence (AI). However, there is also a difference: AI techniques often involve top-to-bottom methods (i.e., methods to the solutions are imposed from the top) whereas CI techniques often involve bottom-up methods (i.e., solutions emerge from unstructured beginnings). An important part of CI includes a set of Nature-inspired computational approaches to address complex problems to which traditional methods are infeasible.
              {/* <a target="_blank" href=""> Read more </a> */}
            </div>

            <h6 className="par_header">Conference Objectives</h6>
            <div className="par">
            Computational Science and Computational Intelligence, both share the same objective: finding solutions to difficult problems. However, the methods to the solutions are different. The main objective of the CSCI Conference is to facilitate increased opportunities for cross-fertilization across CS and CI.
            <br/> <br/>
            The CSCI Conference is committed to encouraging diversity and eliminating discrimination in both its role as a conference and as a provider of services. CSCI aims to create a culture that respects and values each others' differences, that promotes dignity, equality and diversity, and that encourages individuals to develop and maximize their true potential resulting in diversity of products and diversity of solutions to challenging research problems. We are committed wherever practicable to organizing a conference that broadly reflects the international community.
            </div>

            {/* <h6 className="par_header">Featured Keynote</h6>
            <div className="par">
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={12}>
                  Dr. Ram D. Sriram, Ph.D. <br></br>
                  Fellow: IEEE, ASME, AAAS, Chief, Software and Systems Division Information Technology Laboratory National Institute of Standards and Technology, Gaithersburg, Maryland, USA;
                  <br></br>
                  <a target="_blank" href=""> Read more </a>
                </Col>
                <Col xs={12}>
                    <img src="/static/sr.gif" alt=""/>
                </Col>
              </Row>
            </Grid>
            </div> */}

            <h6 className="par_header">INVITATION</h6>
            <div className="par">
            <div className='springerlogocontainer'>
              <div className='normal'>
                <Grid fluid>
                  <Row className="show-grid">
                  <Col xs={3}>
                      <div style={{textAlign: "center", paddingRight: "1rem"}}>
                        <img src="/static/SP1.jpg" style={{width: '100%', maxWidth: '6.5rem'}} alt=""/>
                      </div>
                    </Col>
                    <Col xs={21}>
                      <div>
                      You are invited to submit a paper for consideration. All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI). INDEXATION: Scopus; DBLP, EI Engineering Index (Compendex, Inspec databases); Springer Link; Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
                        {/* <div >(Father of Fuzzy Logic)</div> */}
                      </div>
                    </Col>
                    
                  </Row>
                </Grid>
              </div>  
              <div className='mobile'>
                    <div style={{textAlign: "center", paddingRight: "1rem"}}>
                        <img src="/static/SP1.jpg" style={{width: '100%', maxWidth: '6.5rem'}} alt=""/>
                    </div>
                    <div>
                      You are invited to submit a paper for consideration. All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI). INDEXATION: Scopus; DBLP, EI Engineering Index (Compendex, Inspec databases); Springer Link; Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
                        {/* <div >(Father of Fuzzy Logic)</div> */}
                      </div>
              </div>
            </div>
            
            <br />
            <p className="pub_cps">Past conference proceedings can be accessed via IEEE Xplore Digital Library at: </p>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>
            {/* <p className="pub_cps">The published papers can also be viewed via IEEE Xplore, Scopus, Ei Compendex, and other science indexation systems.</p> */}

            <br/>
            CSCI'24 International Conference is composed of a number of Research Tracks, sessions, workshops, posters and panel discussions; all will be held simultaneously, same location and dates: December 11-13, 2024.
            </div>
            
            <h6 className="par_header">CSCI'24 Research Tracks</h6>
            <div className="par">
            The CSCI conference is composed of the following Research Tracks; all accepted papers will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>) in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI):
              <div className="sym_links">
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCS">Research Track on Computational Science (CSCI-RTCS)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCI">Research Track on Computational Intelligence (CSCI-RTCI)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCW">Research Track on Cyber Warfare, Cyber Defense, & Cyber Security (CSCI-RTCW)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTPC">Research Track on Signal & Image Processing, Computer Vision & Pattern Recognition (CSCI-RTPC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTSC">Research Track on Smart Cities and Smart Mobility (CSCI-RTSC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTED">Research Track on Education (CSCI-RTED) - includes accreditation, such as ABET</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTHI">Research Track on Health Informatics and Medical Systems (CSCI-RTHI)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTMC">Research Track on Mobile Computing, Wireless Networks, & Security (CSCI-RTMC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTSE">Research Track on Software Engineering (CSCI-RTSE)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTOT">Research Track on Internet of Things & Internet of Everything (CSCI-RTOT)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTBD">Research Track on Big Data and Data Science (CSCI-RTBD)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTAI">Research Track on Artificial Intelligence (CSCI-RTAI)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTNA">Research Track on Social Network Analysis, Social Media, & Mining (CSCI-RTNA)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCC">Research Track on Cloud Computing and Data Centers (CSCI-RTCC)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTCB">Research Track on Computational Biology (CSCI-RTCB)</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/csci2024/RTPD">Research Track on Parallel & Distributed Computing (CSCI-RTPD)</Link><br/>
              </div>
              
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Home;