import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISCC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Cloud Computing & Data Centers (CSCI-RTCC)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              Topics of interest include, but are not limited to the following <br/> <br/>

              <h6>Cloud Computing:</h6>
              <div>
              - Big Data Management on Cloud <br/>
              - SaaS, PaaS, and IaaS <br/>
              - SOA and Cloud Computing <br/>
              - Green Information Technologies <br/>
              - Application Portability <br/>
              - Cloud Scalability <br/>
              - Cloud Management and Databases <br/>
              - Use of Cloud for High-performance Computing <br/>
              - Cloudsourcing <br/>
              - Cloud Interoperability <br/>
              - Web-scale Computing <br/>
              - Data Encryption <br/>
              - DevOps and Cloud Computing <br/>
              - Heterogeneous Clouds <br/>
              - Migrating to the Cloud: Experiences and Methodologies <br/>
              - Virtual Private Inter-Cloud <br/>
              - Cloud Reliability and Secure Cloud Computing <br/>
              - Cloud Automation and Mobility <br/>
              - Cloud Policies and Compliance <br/>
              - Cloud Federation <br/>
              - Consumption-based Billing <br/>
              - Cloud Serviceability and Architecture <br/>
              - Cloud Computing and SMBs <br/>
              - Cloud Applications <br/>
              - Enterprise Cloud Orchestration <br/>
              - Fabric Computing <br/>
              - Cloud Computing Development (IDEs, Methodologies, ...)
              </div>

              <br/>

              <h6>Data Centers:</h6>
              <div>
              - Data Center Design and Construction <br/>
              - Enterprise Data Storage Strategies <br/>
              - Server Virtualization <br/>
              - Data Center Outsourcing Services <br/>
              - Data Center Governance Strategies <br/>
              - Mainframe Migration Methods <br/>
              - Mainframe Management Strategies <br/>
              - Data Center Storage and Networking <br/>
              - SDN and other Network Strategies <br/>
              - Co-location, Hosting and Outsourcing Management <br/>
              - Data Center Security <br/>
              - Data Center Backup Strategies <br/>
              - Server Infrastructures and Technologies and Trends <br/>
              - Infrastructure Management and Planning <br/>
              - Mainframe Disaster Recovery <br/>
              - Mainframe Operating Systems <br/>
              - Compliance Issues <br/>
              - Blade Servers and other Similar Technologies <br/>
              - Data Center Energy Efficiency Methods <br/>
              - Data Center Task Scheduling & Methods Including Load Management <br/>
              - Infrastructure Convergence <br/>
              - Efficient Archiving <br/>
              - Application Performance Management <br/>
              - Data Center Storage <br/>
              - Data Center Networking <br/>
              - Hardware Strategies <br/>
              - Capacity Planning <br/>
              - Hardware Virtualization, Operating-system-level Virtualization, and Containers <br/>
              - Managing Data Center Scalability <br/>
              - Best Practices for Data Center Operations <br/>
              - Data Center Budget Management <br/>
              - Data Center Services
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISCC;