import React, { Component } from 'react';
import { Form, Button,Col, Row, Modal, ListGroup, Tabs, Tab, Table  } from 'react-bootstrap';
import country_list from './_countries'
import { push, replace } from 'react-router-redux'
import {connect} from 'react-redux';
import {alertMess, Spinner_Action} from '../../actions';
import {Elements, CardElement, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios"
import conf from '../../_Conf'

const stripePromise = loadStripe(conf.Production? conf.live_key:conf.test_key);

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "20px",
        "::placeholder": {
          color: "#aab7c4",
        },
        fontWeight: "500",
        display: "block",
        padding: 500
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  class CheckoutForm extends React.Component {
    _create_intent = (event) => {
        event.preventDefault();
        this.props.Spinner_Action({show:true})
        const {stripe, elements} = this.props
        if (!stripe || !elements) {
            return;
        }
        
        axios.post(conf.Production? conf.ProductionAddress + 'csci2024/p_create_main__add/' : 'http://127.0.0.1:5050/' + 'csci2024/p_create_main__add/',
        {
            form: {
                ...this.props.form,
                prev_payments: ''
            }
        },
        { headers: { 'Content-Type': 'application/json' } }
        ).then(response => {
            let client_sec = response.data.client_secret
            this.handleSubmit(event, client_sec)
        }).catch( err => {
          this.props.alertMess({isVis: true, message:err.response.data.errors, error:true})
          this.props.Spinner_Action({show:false})
        })
    }
    
    
    handleSubmit = async (event, client_sec) => {
        const {stripe, elements} = this.props
        if (!stripe || !elements) {
          this.props.Spinner_Action({show:false})  
          this.props.alertMess({isVis: true, message:"Please submit again", error:true});
          return;
        }
        const result = await stripe.confirmCardPayment(client_sec, {
            payment_method: {
              card: elements.getElement(CardElement),
              billing_details: {
                name: this.props.form.fn + " " + this.props.form.ln,
              },
            }
          });
      
          if (result.error) {
            this.props.alertMess({isVis: true, message:result.error.message, error:true});
            this.props.Spinner_Action({show:false})
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                console.log("OK!", result)
                this.props.Spinner_Action({show:false})
                this.props.replace(
                  {
                      pathname: '/csci2024/registration_success',
                      state: { form: this.props.form, result: result }
                  })
            }
          }
  
    };
  
    render() {
      return (
        <Form onSubmit={this._create_intent}>
          <div>
            <CardElement options={CARD_ELEMENT_OPTIONS}  />
          </div>

          <div style={{"text-align": "center", margin: "0 auto"}}>
                <Button type="submit" style={{margin: "0.5rem", marginTop: "2rem"}} 
                disabled={
                  !this.props.stripe //|| this.props.Spinner_ActionReducer.show
                }
                onClick={()=>{
                  this.props.Spinner_Action({show:true})
                }} >Confirm and Pay</Button>
                <Button style={{margin: "0.5rem", marginTop: "2rem", backgroundColor:"red", borderColor: "white"}} onClick={()=>{
                    this.props.push("/csci2024/registration_start")
                }}>Cancel</Button>
            </div>
        </Form> 

      );
    }
  }

class Reg_Card2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {}
        }
    }

    componentWillMount = () => {
        try {
            let st = this.props.location.state.form
            this.setState({
                form: st
            })
            console.log(this.props.location.state.form)
        } catch {
            this.props.replace("/csci2024/registration_start")
        }
    }

    render() {
    return (
        <div className="r_container">
        <div className="home">
            <div className="new_banner">
                <div className="banner_content">
                <h1>CSCI 2024</h1>
                <h6>The 2024 International Conference on</h6>
                <h6>Computational Science and Computational Intelligence (CSCI)</h6>
                <h6>December 11-13, 2024, Las Vegas, USA</h6>
                </div>

                <div className="new_banner_overlay"></div>
            </div>

            <div className="page_content">
                <h6 className="par_header">Registration - Confirm and Pay</h6>
                <div className="reg_form">

                  <div className="confirm_everything">
                    <div>
                      <b>Name:</b> {this.props.location.state.form.title} {this.props.location.state.form.fn} {this.props.location.state.form.ln}
                    </div>
                    <div>
                      <b>Email: </b> {this.props.location.state.form.email}
                    </div>
                    <div>
                      <b>University/College/Company: </b> {this.props.location.state.form.uni}
                    </div>
                    <div>
                      <b>Country: </b> {this.props.location.state.form.country}
                    </div>
                    <div>
                      <b>Number of papers: </b> {this.props.location.state.form.pc}
                    </div>
                    {this.props.location.state.form.pc >= 1 &&
                    <div>
                      <b>Paper 1: </b> {this.props.location.state.form.p1_id} - {this.props.location.state.form.p1_title}
                    </div>
                    }
                    {this.props.location.state.form.pc >= 2 &&
                    <div>
                      <b>Paper 2: </b> {this.props.location.state.form.p2_id} - {this.props.location.state.form.p2_title}
                    </div>
                    }
                    {this.props.location.state.form.pc >= 3 &&
                    <div>
                      <b>Paper 3: </b> {this.props.location.state.form.p3_id} - {this.props.location.state.form.p3_title}
                    </div>
                    }
                    <div>
                    <b>Total: </b> 
                      <b style={{color: "red"}}>
                      {
                        (()=>{
                          let cost = 495 * this.props.location.state.form.pc
                          return "$" + cost + ".00"
                        })()
                      }
                      </b>
                    </div>

                  </div>
                  <div className="pay_el">
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                        {({stripe, elements}) => (
                            <CheckoutForm  stripe={stripe} elements={elements} 
                            alertMess={this.props.alertMess} 
                            push={this.props.push} 
                            replace={this.props.replace}
                            form={this.state.form} 
                            Spinner_Action={this.props.Spinner_Action}
                            Spinner_ActionReducer={this.props.Spinner_ActionReducer}
                            />
                        )}
                        </ElementsConsumer>
                    </Elements>
                  </div>
                </div>

            </div>
        
        
        </div>
        </div>

    );
  }
}

const mapStateToProps = state => {
    return {
        alertReducer: state.alertReducer,
        Spinner_ActionReducer: state.Spinner_ActionReducer,
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      alertMess,
      Spinner_Action,
      replace
    }
  }
export default connect(mapStateToProps, mapDispatchToProps())(Reg_Card2);