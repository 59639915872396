import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote4 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
            <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>AI Fairness to Empower Equity in Healthcare:
Addressing Bias in AI-Powered Medical Image Segmentation
</b> <br/>
            Dr. Soheyla Amirian<br/>
            Seidenberg School of Computer Science and Information Systems, Pace University, New York, USA;<br />
            Director, Applied Machine Intelligence Initiatives & Education (AMIIE) Laboratory, USA<br />
            <br />
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            Artificial intelligence (AI) is making significant leaps in healthcare, where the need for fairness and equity in AI applications becomes paramount. This talk will explore the challenges and opportunities associated with fair AI-powered medical image segmentation, focusing on hip and knee bony anatomy. While advancements in AI have transformed pre-operative planning and post-operative assessment, inherent biases within these models pose significant risks to equitable healthcare delivery. We will discuss our proposed computational strategy that examines a potential source of biases in AI-driven segmentation algorithms, particularly as they relate to sex, race, and age. By utilizing plain radiographs, we aim to ensure our findings are applicable in diverse healthcare settings, especially those with limited access to advanced imaging technologies. This talk not only identifies critical biases but also presents targeted mitigation strategies to create an AI segmentation framework that is fair, impartial, and safe.
            </div>

            <h6 className="par_header">Speaker’s Biography:</h6>
            <div className="par">
            Dr. Soheyla Amirian is an Assistant Professor at the Seidenberg School of Computer Science and Information Systems, Pace University, NY, where she is leading the Applied Machine Intelligence Initiatives & Education (AMIIE) Laboratory. She has been actively collaborating with a multidisciplinary team of faculty members, students, and principal investigators to build and validate AI algorithms in various real-world applications, including public health, digital health sciences, and imaging informatics. Dr. Amirian earned her BSc, MSc, and PhD in Computer Science, specializing in AI and deep learning computer vision. She has received numerous accolades and recognitions, including the 2023 IEEE Atlanta Section Outstanding Educator award, the ACM Richard TAPIA Conference Scholarship in 2022 and 2020, the 2021 UGA Outstanding Teaching Assistant award, the 2019 International Conference on Computational Science and Computational Intelligence (CSCI) Outstanding Achievement award, the NVIDIA GPU award, and has authored over 30 peer-reviewed publications. She has organized several conferences and tutorials on prestigious computational intelligence venues, such as ISVC and IEEE ICHI. Dr. Amirian’s research has been supported by US federal agencies, including NIH National Institute on Aging (NIA).
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/amirian1024.png" height="300px" alt=""/>
            </div>
            </div>          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote4;