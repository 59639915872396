import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISCB extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Computational Biology (CSCI-RTCB)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Prologue:</h6>
              <div>
              Computational Biology involves the development and application of
              data-analytical and theoretical methods, mathematical modeling and
              computational simulation techniques to the study of biological,
              behavioral, and social systems. An important core of Computational Biology
              includes Bioinformatics. Bioinformatics is an interdisciplinary field that
              develops methods and software tools for understanding biological data.
              Topics of interest include, but are not limited to the following:
              <br/> <br/>

              - Bioinformatics and Big Data <br/>
              - Big Data in Genomics and Cancer Research <br/>
              - High-performance Computing and Applications in Biology <br/>
              - Microarrays <br/>
              - Bioinformatics and Cloud Resources <br/>
              - Molecular Dynamics and Simulation; Molecular Interactions <br/>
              - Molecular Sequence Classification, Alignment and Assembly <br/>
              - Molecular Sequence and Structure Databases <br/>
              - Computational Systems Biology <br/>
              - Gene Regulation <br/>
              - Gene Pattern Discovery and Identification <br/>
              - Gene Expression Analysis; Gene Expression Databases <br/>
              - Genetic Network Modeling and Inference <br/>
              - Comparative Genomics <br/>
              - Evolution of Regulatory Genomic Sequences <br/>
              - RNA and DNA Structure and Sequencing <br/>
              - Biomedical Engineering <br/>
              - Combinatorics and Bioinformatics <br/>
              - Biological Data Mining and Knowledge Discovery <br/>
              - Bio-ontologies + Semantics <br/>
              - Biological Data Integration and Visualization <br/>
              - Image Processing in Medicine and Biological Sciences <br/>
              - Pattern Classification and Recognition <br/>
              - Sequence Analysis and Alignment <br/>
              - Informatics and Statistics in Biopharmaceutical Research <br/>
              - Software Tools and Methods for Computational Biology and Bioinformatics <br/>
              - Protein Modeling <br/>
              - Proteomics; Protein Folding and Fold Recognition <br/>
              - Metabolic Modeling and Pathways <br/>
              - Evolution and Phylogenetics <br/>
              - Macromolecular Structure Prediction <br/>
              - Medical Informatics <br/>
              - Epidemic Models <br/>
              - Structural and Functional Genomics <br/>
              - Amino Acid Sequencing <br/>
              - Stochastic Modeling <br/>
              - Cheminformatics <br/>
              - Computational Drug Discovery and Experimental Medicine <br/>
              - Cancer Informatics <br/>
              - Experimental Studies <br/>
              - Application of Computational Intelligence in Drug Design <br/>
              - Computer-based Medical Systems (automation in medicine, ...)


              </div>
              

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISCB;