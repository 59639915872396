import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISCI extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Computational Intelligence (CSCI-RTCI)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Fuzzy Logic Systems:</h6>
              <div>
              Fuzzy logic and fuzzy set theory; Computing with words; Neural-fuzzy systems; Fuzzy and rough data analysis; Fuzzy optimization and design; Fuzzy decision making; Systems modeling and identification; Systems architect2ures and hardware; Control and systems; Fuzzy logic applications.
              </div>
              <br/>

              
              <h6>Big Data and Data Analytic:</h6>
              <div>
              Big Data Services; Optimization and data analytics; Machine learning technologies; Knowledge extraction and business processes; Big Data to knowledge mapping; Information engineering; Big Data and decision sciences; Data and information mining; Case studies; and Applications.
              </div>
              <br/>

              <h6>Neural Networks:</h6>
              <div>
              Neural network theory and models; Evolutionary neural systems; Collective intelligence; Computational neuroscience; Cognitive models; Neurodynamics; Neuroinformatics; Neuroengineering; Neural hardware; Mathematical modeling of neural systems; Hybrid systems; Self-aware systems; Agent-based systems; Artificial life; and Neural network applications.
              </div>
              <br/>

              <h6>Evolutionary Computations:</h6>
              <div>
              Metaheuristic optimization algorithms; Evolutionary algorithms; Genetic algorithms; Evolutionary programming; Evolution strategy; Particle swarm optimization; Ant colony optimization; Artificial immune systems; Differential evolution; Learning classifier systems; Learnable evolution models; Self-organizing maps and competitive learning; Multi-objective evolutionary algorithms; Reinforcement learning; Parallel simulated annealing; Cultural algorithms; Intelligent, bio-inspired and autonomic computing.
              </div>
              <br/>

              <h6>Artificial Intelligence (AI):</h6>
              <div>
              All aspects of AI as they relate to Computational Intelligence, including: Brain models and cognitive science; Natural language processing; Fuzzy logic and soft computing; Software tools for AI; Expert systems; Decision support systems; Automated problem solving; Knowledge discovery; Knowledge-intensive problem solving techniques; Knowledge networks and management; Intelligent information systems; Intelligent data mining and farming; Intelligent web-based business; Intelligent agents; Intelligent user interface; Intelligent tutoring systems; Reasoning strategies; Heuristic search methods; Languages and programming techniques for AI; Constraint-based reasoning and constraint programming; Intelligent information fusion; Multisensor data fusion using neural and fuzzy techniques; Integration of AI with other technologies; Evaluation of AI tools; Social intelligence (markets and computational societies); Social impact of AI; and Satisfiability methods.
              </div>
              <br/>

              <h6>Applications of Computational Intelligence:</h6>
              <div>
              Pattern recognition applications; Machine vision; Brain-machine interface; Embodied robotics; Biometrics; Computational biology; Bioinformatics; Information mining and forecasting; Sensor networks; Information processing; Internet and multimedia; DNA computing; Machine learning applications; Multi-agent systems applications; Game technologies; Computational ocean and earth sciences; Computational chemistry and biochemistry; Computational physics; Medical applications; Transportation systems and simulations; Structural engineering; Computational electro-magnetic; Computer graphics and multimedia; Dynamic systems; Computational finance; Information mining and applications; Astrophysics; Biometric modeling; Geology and geophysics; Nuclear physics; Computational journalism; Computational sociology; Geographical Information Systems (GIS) and remote sensing; Military and defense related applications; Ubiquitous computing; and Emerging applications.
              </div>
              <br/>

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISCI;