import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';


class Authors extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Instructions for Authors of Accepted Papers</h4>
            <div className="par">
               <b>Congratulations</b> on having a paper accepted for publication in the CSCI'24 conference proceedings. This page contains useful information for authors who will be publishing their papers in the proceedings  and presenting their papers at the conference. 
            </div>

            <h6 className="par_header">Conference Registration</h6>
            <div className="par">
            In order to have their paper(s) published in conference proceedings, <b>at least one author for each accepted paper is required to register before the announced deadline</b>. If more than one author plan to attend the conference, then each author is required to register. 
            &nbsp;<Link to="/csci2024/registration">Click Here&nbsp;</Link>
            to go to the conference registration page for more details.
            </div>

            <h6 className="par_header">Hotel Reservation</h6>
            <div className="par">
            To obtain information about the hotel accommodations and to book your hotel room <Link to="/csci2024/venue">Click Here</Link>.
            </div>

            <h6 className="par_header">Typesetting Instructions for Preparation of Final Camera-ready Papers</h6>
            <div className="par">
            Please note that the instructions for the preparation of Camera-ready Papers are intended to give consistency of style and format. Failure to comply with these instructions may result in your paper being rejected and cause a delay in its publication.  
            <b> The link to the instructions has been emailed to the authors of the accepted papers.</b> 
            </div>

            <h6 className="par_header">Submission of Final Camera-ready Papers</h6>
            <div className="par">
            Final Camera-ready Papers must be uploaded to the publication website. The deadline and also the link to the publication website will be emailed to authors of accepted papers soon AFTER the conference.
            </div>

            <h6 className="par_header">Paper Categories and Presentation Format</h6>
            <div className="par">
            The Conference Paper Selection Committee has approved the following categories of accepted papers - please refer to: <br />
            <a href="https://www.american-cse.org/csci2024/paper_categories" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/paper_categories</a> <br />
            Refer to the following link for mode of presentations (OPTIONS): <br />
            <a href="https://www.american-cse.org/csci2024/presentation_modes" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/presentation_modes</a>

            </div>

            <h6 className="par_header">Invitation Letters for US Visa</h6>
            <div className="par">
            To request an "Invitation Letter for US Visa" for participation in CSCI'24, please follow the instructions that appear on a particular website; the link to this particular website has been emailed to the authors of the accepted papers.
            <br/> <br/>
            <b>Visa Information for International Participants Traveling to the United States</b>
            <br/> <br/>
            We advise scientists traveling to the United States to apply for a USA visa as early as possible. Below are helpful links to various websites with general visa information: (IMPORTANT: The conference cannot guarantee the issuance of USA visa; authors whose USA visa is not issued in a timely manner can present their papers online.)
            <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://www.usembassy.gov/">Websites of U.S. Embassies and Consulates Around the World</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="http://sites.nationalacademies.org/PGA/biso/visas/">International Visitor's Office</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="http://travel.state.gov/content/visas/en/visit/visitor.html">B-1 Visa (How to apply)</a><br/>

            <br/>
            For additional information regarding US visa, please contact your <a target="_blank" href="https://www.usembassy.gov/">local US embassy</a>.
            </div>
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Authors;