
// reducers...

import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

const alertReducer = (state={
  isVis: false,
  message: "",
  error: true
}, action) => {
  switch(action.type){
      case 'ALERT':
          return {
              isVis: action.payload.isVis,
              message: action.payload.message,
              error: action.payload.error,
          }
      default:
          return state
  }
}

const modalsActionReducer = (state={
  add_app__newP:{
    modalShow: false,
  },
  add_app__SearchP:{
    modalShow: false,
  },
}, action) => {
  switch(action.type){
      case 'MODAL_ADD_APP__NEWP_OPEN':
          return {
            add_app__newP:{
              modalShow: true,
            },
            add_app__SearchP:{
              modalShow: false,
            },
          }
      case 'MODAL_ADD_APP__NEWP_CLOSE':
        return {
          add_app__newP:{
            modalShow: false,
          },
          add_app__SearchP:{
            modalShow: false,
          },
        }
      case 'MODAL_ADD_APP__SEARCHP_OPEN':
        return {
          add_app__newP:{
            modalShow: false,
          },
          add_app__SearchP:{
            modalShow: true,
          },
        }
      case 'MODAL_ADD_APP__SEARCHP_CLOSE':
        return {
          add_app__newP:{
            modalShow: false,
          },
          add_app__SearchP:{
            modalShow: false,
          },
        } 
      default:
          return state
  }
}

const resizeActionReducer = (state={
  width: 0,
  height: 0,
  expanded: true
}, action) => {
  switch(action.type){
      case 'RESIZE':
          return {
              width: action.payload.width,
              height: action.payload.height,
              expanded: action.payload.expanded
          }
      default:
          return state
  }
}

const Spinner_ActionReducer = (state={show:false}, action) => {
  switch(action.type){
    case 'SPINNER':
        return action.payload.show
    default:
        return state
  }
  }


const rootReducer = combineReducers({
  routing: routerReducer,
  
  alertReducer: alertReducer,
  modalsActionReducer: modalsActionReducer,
  resizeActionReducer: resizeActionReducer,
  Spinner_ActionReducer: Spinner_ActionReducer,
});

export default rootReducer;
