import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISCS extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Computational Science (CSCI-RTCS)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Big Data and Data Analytic:</h6>
              <div>
              Software and hardware architectures; Big Data visualization; Services; Data analytics; toolkits; open platforms; business processes; Managing, analyzing, and using large volumes of structured and/or unstructured data; Simulation and modeling; Consumerization of Big Data; Big Data in social media; Big Data and decision sciences and analytics; Data and text mining; Crowdsourcing; Case studies; and Applications.
              </div>
              <br/>

              <h6>High Performance Computing and Communication Systems:</h6>
              <div>
              Cluster computing; Supercomputing; Cloud computing; Autonomic computing; P2P computing; Mobile computing; Grid computing; Parallel/distributed architectures and algorithms; Networks and interconnection networks; Reliability and fault-tolerance; The use of building block processors; Real-time and embedded systems; Multimedia communications, systems, and applications; Software tools and environments for computational science; Performance analysis, evaluation and monitoring; Wireless networks and distributed systems; FPGA, multicore, GPU, SOC and applications; Nanotechnology in HPC; High-performance mobile computation and communication; Petri Nets; Web-based simulation and computing; Emerging technologies.
              </div>
              <br/>

              <h6>Scientific Computing:</h6>
              <div>
              Algorithms and Methods: Monte Carlo methods and applications; Numerical methods and simulation; Quantum computing; Computational number theory; Optimization and approximation methods; Probabilistic and randomized methodologies; Computational geometry; Computational chemistry; Computational fluid dynamics; Computational physics; Computational mechanics; Inversion problems; Cellular automata; Ordinary and partial differential equations; Stochastic differential equations; Integral equations; Finite element methods; Dynamical systems; Non-symmetric solvers; Engineering applications.
              <br/> <br/>
              High Performance Computing and Communication Systems: Cluster computing; Supercomputing; Cloud computing; Mobile computing; Grid computing; Parallel/distributed architectures and algorithms; Networks and interconnection networks; Reliability and fault-tolerance; Real-time and embedded systems; Software tools and environments for Scientific Computing; Distributed systems; FPGA, multicore, GPU, SOC and applications; High-performance mobile computation and communication; Emerging technologies.
              <br/> <br/>
              Modeling, Simulation and Visualization Methods: Computational modeling and simulation in science and engineering; Simulation languages and tools; Information and scientific visualization; Modeling methodologies; Visual interactive simulation and modeling; Visualization tools and systems for simulation and modeling; CAD/CAE/CAM; Agent based simulation; Analytical and stochastic modeling techniques and applications; Simulation of complex systems; Simulation of intelligent systems; Biomedical visualization and applications; Discrete and numeric simulation; and Modeling and simulation frameworks.
              <br/> <br/>
              Big Data and Data Analytic: Software and hardware architectures; Big Data visualization; Services; Data analytics; toolkits; open platforms; business processes; Managing, analyzing, and using large volumes of structured and/or unstructured data; Big Data and decision sciences and analytics; Scientific Crowd-sourcing; Case studies; and Applications.
              </div>
              <br/>

              <h6>Image Processing and Computer Vision:</h6>
              <div>
              Multi-resolution vision techniques; Machine learning technologies for  vision; Active and robot vision; Cognitive and biologically inspired vision; Dimensionality reduction methods in pattern recognition; Classification and clustering techniques; Statistical pattern recognition; Image-based modeling and algorithms; Illumination and reflectance modeling; Motion and tracking algorithms; Biometric authentication; Medical image processing and analysis; Segmentation techniques; Geometric modeling and fractals; Image data structures and databases; Image compression; Image feature extraction; Enhancement techniques; Novel noise reduction algorithms; Mathematical morphology; 3D imaging; Watermarking methods and protection; Wavelet methods; Image restoration; Video analysis; Object recognition; and Case studies and applications.
              </div>
              <br/>

              <h6>Information and Knowledge Engineering:</h6>
              <div>
              Information retrieval systems and databases; Information and knowledge structures; Knowledge management and cyber-learning; Information reliability and security; Knowledge mining; Knowledge classification tools; Knowledge representation and acquisition; Large-scale information processing methods; Intelligent knowledge-based systems; Aspect-oriented programming; Formal and visual specification languages; Decision support and expert systems; Ontology engineering, sharing and reuse; Ontology matching and alignment; Agent-based techniques and systems; Workflow management; Large-scale information processing methods and systems; Database engineering and systems; Data-web models and systems; Data warehousing and datacenters; Data security and privacy issues; Quantum information theory; Natural language processing; Information integration; Domain analysis and modeling.
              </div>
              <br/>

              <h6>Algorithms and Methods:</h6>
              <div>
              Monte Carlo methods and applications; Numerical methods and simulation; Quantum computing; Computational number theory; Optimization and approximation methods; Probabilistic and randomized methodologies; Computational geometry; Computational biology; Computational chemistry; Computational fluid dynamics; Computational physics; Computational mechanics; Computational electromagnetics and computational electrodynamics; computational sociology; Splines and wavelets; Inversion problems; Cellular automata; Ordinary and partial differential equations; Stochastic differential equations; Finite element methods; Multi-level and Multi-grid methods; Operational research; Dynamical systems; Non-symmetric solvers; Engineering problems and emerging applications.
              </div>
              <br/>

              <h6>Applications of Computational Science:</h6>
              <div>
              Pattern recognition applications; Brain-machine interface; Biometrics; Computational biology; Bioinformatics; Image and signal processing; Information mining and forecasting; Sensor networks; Information processing; Internet and multimedia; DNA computing; Machine learning applications; Multi-agent systems applications; Telecommunications; Transportation systems; Intrusion detection and fault diagnosis; Game technologies; Material sciences; Space, weather, climate systems and global changes; Computational ocean and earth sciences; Computational chemistry and biochemistry; Computational physics; Transportation systems and simulations; Computer graphics and multimedia; Computational finance; Information mining and applications; Astrophysics; Geology and geophysics; Nuclear physics; Computational journalism; Computational sociology; Geographical Information Systems (GIS) and remote sensing; Military and defense related applications; Ubiquitous computing; and Emerging applications.
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISCS;