import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Demo extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Demography History</h4>
            <div className="par">
            <h6>Based on 2014 through 2023 participation data:</h6>
            In the past, about 42% of the attendees have been from outside USA; from 76 nations. 59% of registrants have been from academia, 29% from industry; 11% from government agencies and research labs; and 1% unknown. The conference has had participants, speakers, authors affiliated with over 372 different institutions and universities; including many from the top 40 ranked institutions and universities.  A very small subset of the affiliations of participants in the recent past appears below:
            <br /><br />
            MAJOR IT CORPORATIONS, INCLUDING: <br />
            Microsoft, Accenture, Google/Alphabet, Apple, Facebook/Meta, Oracle, Amazon, Yahoo, Samsung Electronics, IBM, Ebay, Siemens, Philips, Ericsson, GE, BAE Systems, Hewlett Packard (HP), Hitachi, NTT, Intel Corp, BTS Software Solutions, NetApp, Algorkorea Co., Paris Saclay, ElderSafe Technologies Inc, METRON (France), Fraunhofer IKTS (Germany), Wipro Technologies, VNIIEF (Russia), BTU Cottbus-Senftenberg (Germany), Synchrone Technologies (France), Infosys (India), Bosch Software Systems (Germany), Tata Consultancy Services, SAP, Capgemini (France), Cognizant (USA), State Grid (China), Foxconn (Taiwan), AT&T, Huawei Technologies Co., Ltd. (China), Dell Technologies, Sony (Japan), Tencent (China), Panasonic (Japan), Lenovo (China), LG Electronics (South Korea), Alibaba (China), Naver Corp (South Korea), Baidu (China), China Mobile, ...
            <br /> <br />
            MAJOR CORPORATIONS, INCLUDING: <br />
            Exxon Mobil, JPMorgan Chase, Raytheon, PetroChina, GlaxoSmithKline, BCL Technologies, CERENA Petroleum Group, Aerospace Electronic Technology (China), Max Planck - Genetics (Germany), Tigard Research Institute, Bank of China, ENGIE (France), Forschungszentrum Juelich (Germany), Secured Smart Electric Vehicle (Korea), China National Petroleum, BASF (Germany), Bayer (Germany), Johnson & Johnson, GlaxoSmithKline, HSBC, Airbus, Boeing, Hyundai, Goldman Sachs, Deutsche Bank (Germany), Sinopec Group (China), United Health (USA), Volkswagen (Germany), China Construction Bank, Barclays Bank (UK), Saudi Aramco (Saudi Arabia), BP (United Kingdom), Royal Dutch Shell (Netherlands), Daimler (Germany), China Railway Engineering Corp., Cigna (USA), Trafigura (Singapore), Tesla (USA), Glencore (Switzerland), Ford (USA), AXA (France), Goldman Sachs (USA), Taiwan Semiconductor (Taiwan), Toyota (Japan), ...
            <br /><br />
            GOVERNMENT RESEARCH AGENCIES AND NATIONAL LABORATORIES, INCLUDING: <br />
            National Science Foundation (NSF), National Institutes of Health (NIH), US Air Force (multiple research labs), National Security Agency (NSA), Pacific Northwest National Lab (PNNL), National Aeronautics and Space Administration (NASA), Lawrence Berkeley National Lab (LLNL), Lawrence Livermore National Lab, Los Alamos National Lab (LANL), Argonne National Lab, Cold Spring Harbor Lab, US National Cancer Institute, Swedish Defence Research Agency, US National Institute of Standards and Technology (NIST), Oak Ridge National Lab, U.S. Geological Survey, US National Library of Medicine, SPAWAR Systems Center Pacific, CERN (Switzerland), China NSF, Russian Academy of Sciences, Sandia National Lab, Savannah River National Lab, US Navy, Ames Lab, Hasso Plattner Institute, US Army, Korea Internet & Security Agency, DESY (Germany), LNGS (Italy), Suez Canal Research Center, Okinawa Bureau (Japan), Canadian Medical Protective Association, Osong Medical Innovation Foundation (Korea), Royal Observatory Edinburgh (UK), Jet Propulsion Laboratory (JPL), Gran Sasso National Lab (Italy), National Institute of Informatics (Japan), Frederick National Lab for Cancer Research, Idaho National Laboratory, ...; and three Venture Capitalists and nine Intellectual Property attorneys, ...

            <br /><br />
            We anticipate to have attendees from about 65+ countries. To get a feeling about the CSCI conferences' atmosphere, see some delegates photos available at (over 2,000 photos): <br />
            <a href="https://www.american-cse.org/csci2024/gallery " target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/gallery</a> <br />
            <a href="https://www.american-cse.org/csce2024/gallery " target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csce2024/gallery</a> <br />
            <a href="https://photos.ucmss.com/ " target="_blank" rel="noopener noreferrer">https://photos.ucmss.com/</a>

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Demo;