import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PresMs extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Presentation Modes</h4>
            <div className="par">
                In case of hesitation by some authors/speakers to travel during Year 2024 (due to Coronavirus/COVID-19 or other pandemics), the CSCI Steering Committee has developed the following policy in regards to presentation modes:
                <br/><br/>
                If an author of an accepted paper registers for the conference but for some reason he/she is unable to physically attend the conference: The paper of the registered author would still be published and arrangements would be made for the published proceedings/books to be made available to the author after the conference. Therefore, for Year 2024, the non-attendance by the author would not negatively impact the publication of his/her paper. <br /> <br />
                Registered authors are requested to select one of the presentation options that appear below. Please email your selection to cs@american-cse.org by November 25, 2024 (this will help the program committee to manage the logistics of the preparation of the presentation schedules).
                <br/><br/>
                Select one option:
                <br /> <br />
                <b>- OPTION 1: </b> Presenting the paper on site (face-to-face) at the congress. A 20-minute presentation; includes Q & A: You would have to be physically present. Please bring your PowerPoint/slides/... presentation on a standard USB Flash Memory. Each conference room is equipped with a Laptop, Projector, ...
                <br/><br/>
                <b>- OPTION 2: </b> Presenting the paper remotely: using Zoom (interactive). A 20-minute presentation; includes Q & A: The conference (via cs@american-cse.org) will email the speakers who select this option a zoom link/code, the date and time of presentation; this information will be provided in early December 2024. Most speakers use "voice-over" slides presentation - but this is entirely up to the speakers.
                <br/><br/>
                <b>- OPTION 3: </b> Presenting the paper remotely (non-interactive). A 20-minute pre-recorded presentation. Speakers to provide the URL/web/link from where their presentation can be accessed. The web link should be emailed to cs@american-cse.org by December 10, 2024. The conference would then compile these presentation links into a file - this file would then be made available on the CSCI 2024 web site after the conference. These presentations will be made available to the research community; they will have a high level of exposure. Most speakers use "voice-over" slides presentation - but this is entirely up to the speakers. 
                Most who choose this option, provide a youtube clip (example: <a href="https://www.youtube.com/watch?v=_rMG6CLRUWg" target="_blank" rel="noopener noreferrer">https://www.youtube.com/watch?v=_rMG6CLRUWg</a> ). There should be no password (since, the link will be provided to the science community at-large). 

                <br /> <br />
                If an author of a poster paper plans to physically attend the conference, then he/she should bring a maximum of 12 pages (8.5 X 11 inch) describing his/her paper (or one large poster). These pages will be pinned to Presentation Boards (48x28 inch) during the Discussion/Poster Sessions. Please use large fonts so that the paper can be read from about 1.5 meter distance - any reasonable typesetting format is acceptable. However, if an author of a poster paper cannot physically attend the conference, then he/she can choose a remote mode of presentation.
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

export default PresMs;