import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISMC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Mobile Computing, Wireless Networks, & Security (CSCI-RTMC)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Mobile Computing:</h6>
              <div>
              Human-Computer Interaction; Usability Studies; Mobile Communications;
   Mobile Computing; Mobile Hardware; Mobile Software; Hardware-Software
   Co-Design; Mobile Network Infrastructures; Protocols; Novel Mobile
   Devices; Smart Cards; Wearable Devices; Mobile Internet; Mobile e-Commerce
   and Multimedia; Carputers and Technologies; Application-specific Mobile
   Systems; Software Infrastructures for Pervasive System; Service Discovery
   Mechanisms and Protocols; Energy Aware Systems; Positioning and Tracking
   Technologies; Ubiquitous Computing; Middleware Services and Agent
   Technologies; Mobile Computing Applications and Emerging Technologies.
              </div>

              <br/>

              <h6>Wireless Networks:</h6>
              <div>
              Power Management and Control, Low-Power Protocols; Satellite-based Systems;
   Broadcast Networks; High Altitude Platforms; GPS, Location-based Service
   and Mobile Devices; Mobile Agents; Wireless and Mobile Applications;
   Routing, Multicasting, ...; Resource Management, Wireless QoS; Wireless
   Network Architectures; Wireless Security; Personal Area Networks and Body
   Wireless Networks; Wireless Sensor and Actuator Networks; Node Placement
   and Topology Control Issues; Multi-user Detection; Mobile Wireless QoS,
   Radio Resource Management; Heterogeneous Wireless Networks, Radio Access
   Networks; Software-defined Radio, Reconfigurable Radio Networks; Wireless
   IP Networks, Interworking; Wireless Multimedia, QoS Adaptation; Disruption
   Tolerant Networks and Applications; Distributed Algorithms for Wireless
   Networks; Autonomous Mobile Sensor Networks; Sensor Networks; Ad Hoc
   Networks; Vehicular Networks; Underwater Networks; and Underground
   Networks; and Applications in Healthcare, Medical Imaging, Environment
   Monitoring, Industrial and Structural Monitoring, Smart Grids and Energy
   Control Systems, Smart Buildings and Smart Cities, Home Monitoring and
   Assisted Living Applications, Wireless Surveillance, Smart Fabrics and
   Wearables, Defense and Security, Vehicular Networks, and novel applications.
              </div>

              <br/>


              <h6>Security:</h6>
              <div>
              Network Security; Security Algorithms; Mobile Network Security; Security
   in Contents Distribution Networks; Virtual Private Network (VPN) Security;
   Tracing Techniques in Internet; Active Networks; Security in Grid and
   Cloud; Web Monitoring and Traps; Control Systems & SCADA Security;
   Transport-Level Security; IP Security; Electronic Mail security;
   Cryptographic and Related Methods; Security Protocols; Message
   Authentication Methods; Anonymity Technologies; Digital Signature Methods;
   Security Management; Surveillance Technologies; Security Policies; Channel
   Management; Digital Contents Copyright Protection; System Security
   Management; Information Security Management; Government Security Policy;
   Web Penetration Testing; Vulnerabilities Management; Information Assurance;
   Mission Assurance; Risk Assessment and Risk Management; Cyber Security
   Compliance; Security Auditing; Disaster Recovery; Access Control; Secure
   Use of Software; Biometrics and Forensics; Face Recognition Systems; Cyber
   Forensics; Forensic Analysis; Feature Extraction and Matching Algorithms;
   Embedded Systems Security; Security Architectures; Fault Attacks; Hardware
   Tamper Resistance; Smart Card Processors; Secure Storage Devices; Security
   in E-Commerce and M-Commerce; Watermarking; Cloud Computing Security;
   Database Security; Data Mining Security; Cyber Security Automation;
   Firewall Systems; Hacking Techniques and Related Issues; Intrusion
   Detection System (IDS); Honeypot; Virus Issues (Detection, Prevention ...);
   Attack Graphs; Malware Analysis; and Emerging Security Technologies.

              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISMC;