import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISAI extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Artificial Intelligence (CSCI-RTAI)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            {/* <div style={{textAlign: "center", padding: "1rem"}}>
                        <img src="/static/SP1.jpg" style={{width: '100%', maxWidth: '8.5rem'}} alt=""/>
              </div> */}
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding ">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              <h6>Prologue:</h6>
              <div>
                Artificial Intelligence (AI) is the science and engineering of making
                intelligent machines and systems. This is an important multi-disciplinary
                field which is now an essential part of technology industry, providing
                the heavy lifting for many of the most challenging problems in
                computational science. Since Machine Learning has strong ties with AI,
                this research track also covers the field of Machine Learning. The list of
                topics below is by no means meant to be exhaustive.
              </div>

              <br/>

              <h6>Artificial Intelligence:</h6>
              <div>
              Brain models, Brain mapping, Cognitive science; Natural language
              processing; Fuzzy logic and soft computing; Software tools for AI; Expert
              systems; Decision support systems; Automated problem solving; Knowledge
              discovery; Knowledge representation; Knowledge acquisition; Knowledge-
              intensive problem solving techniques; Knowledge networks and management;
              Intelligent information systems; Intelligent data mining and farming;
              Intelligent web-based business; Intelligent agents; Intelligent networks;
              Intelligent databases; Intelligent user interface; AI and evolutionary
              algorithms; Intelligent tutoring systems; Reasoning strategies;
              Distributed AI algorithms and techniques; Distributed AI systems and
              architectures; Neural networks and applications; Heuristic searching
              methods; Languages and programming techniques for AI; Constraint-based
              reasoning and constraint programming; Intelligent information fusion;
              Learning and adaptive sensor fusion; Search and meta-heuristics; Multi-
              sensor data fusion using neural and fuzzy techniques; Integration of
              AI with other technologies; Evaluation of AI tools; Social intelligence
              (markets and computational societies); Social impact of AI; Emerging
              technologies; and Applications (including: computer vision, signal
              processing, military, surveillance, robotics, medicine, pattern
              recognition, face recognition, finger print recognition, finance and
              marketing, stock market, education, emerging applications, ...).
              </div>

              <br/>


              <h6>Machine Learning:</h6>
              <div>
              Statistical learning theory; Unsupervised and Supervised Learning;
              Multivariate analysis; Hierarchical learning models; Relational
              learning models; Bayesian methods; Meta learning; Stochastic
              optimization; Simulated annealing; Heuristic optimization techniques;
              Neural networks; Reinforcement learning; Multi-criteria reinforcement
              learning; General Learning models; Multiple hypothesis testing;
              Decision making; Markov chain Monte Carlo (MCMC) methods; Non-
              parametric methods; Graphical models; Gaussian graphical models;
              Bayesian networks; Particle filter; Cross-Entropy method; Ant colony
              optimization; Time series prediction; Fuzzy logic and learning;
              Inductive learning and applications; Grammatical inference; Graph
              kernel and graph distance methods; Graph-based semi-supervised
              learning; Graph clustering; Graph learning based on graph
              transformations; Graph learning based on graph grammars; Graph
              learning based on graph matching; Information-theoretical approaches
              to graphs; Motif search; Network inference; Aspects of knowledge
              structures; Computational Intelligence; Knowledge acquisition and
              discovery techniques; Induction of document grammars; General
              Structure-based approaches in information retrieval, web authoring,
              information extraction, and web content mining; Latent semantic
              analysis; Aspects of natural language processing; Intelligent
              linguistic; Aspects of text technology; Biostatistics; High-
              throughput data analysis; Computational Neuroscience; and
              Computational Statistics.
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISAI;