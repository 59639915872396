import React, { Component } from 'react';
import { Form, Button,Col, Row, Modal, ListGroup, Tabs, Tab, Table  } from 'react-bootstrap';
import country_list from './_countries'
import { push, replace } from 'react-router-redux'
import {connect} from 'react-redux';
import {alertMess} from '../../actions';

class Reg_Form extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onNext1 = () => {
        this.props.alertMess({isVis: false, message:'', error:false});
        let obj = {
            title: document.getElementById("reg_f_title").value,
            fn: document.getElementById("reg_f_fn").value,
            ln: document.getElementById("reg_f_ln").value,
            country: document.getElementById("reg_f_country").value,
            uni: document.getElementById("reg_f_comp").value,
            email: document.getElementById("reg_f_email").value,
        }
        if(obj.title == "Select"){this.props.alertMess({isVis: true, message:'Please select title', error:true}); return;}
        if(obj.fn == ""){this.props.alertMess({isVis: true, message:'Please insert first name', error:true}); return;}
        if(obj.ln == ""){this.props.alertMess({isVis: true, message:'Please insert last name', error:true}); return;}
        if(obj.country == "Select"){this.props.alertMess({isVis: true, message:'Please select country', error:true}); return;}
        if(obj.uni == ""){this.props.alertMess({isVis: true, message:'Please insert university/college/company', error:true}); return;}
        if(obj.email == ""){this.props.alertMess({isVis: true, message:'Please insert email', error:true}); return;}
        
        this.props.replace(
            {
                pathname: '/csci2024/registration_fee_calc',
                state: { form: obj }
            })
    }

    render() {
    return (
        <div className="r_container">
        <div className="home">
            <div className="new_banner">
                <div className="banner_content">
                <h1>CSCI 2024</h1>
                <h6>The 2024 International Conference on</h6>
                <h6>Computational Science and Computational Intelligence (CSCI)</h6>
                <h6>December 11-13, 2024, Las Vegas, USA</h6>
                </div>

                <div className="new_banner_overlay"></div>
            </div>

            <div className="page_content">
                <h6 className="par_header">Registration - Personal Info</h6>
                <div className="reg_form" style={{"max-width": "40rem", margin: "2rem auto"}}>
                    <Form>
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Title: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="reg_f_title" as="select">
                                <option>Select</option>
                                <option>Mr.</option>
                                <option>Ms.</option>
                                <option>Prof.</option>
                                <option>Dr.</option>
                            </Form.Control>
                        </Col>
                    </Row>
    
                    <Row>
                        <Col sm={4}>
                            <Form.Label>First Name: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="reg_f_fn" />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Last Name: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="reg_f_ln" />
                        </Col>  
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Country: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="reg_f_country" as="select" >
                                <option>Select</option>
                                {
                                    country_list.map((i)=>{
                                        return <option id={i.code}>{i.name}</option>
                                    })
                                }
                            </Form.Control>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>University/College/Company: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="reg_f_comp" />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4}>
                            <Form.Label>Email: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="reg_f_email"  />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Button onClick={this.onNext1}>
                            Next
                        </Button>
                    </Row>
                </Form>
              </div>

            </div>
        
        
        </div>
        </div>

    );
  }
}

const mapStateToProps = state => {
    return {
        alertReducer: state.alertReducer,
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      alertMess,
      replace
    }
  }
export default connect(mapStateToProps, mapDispatchToProps())(Reg_Form);