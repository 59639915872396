import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Keynote3 extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
            <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Keynotes/Invited Lectures</h4>
            <br />
            <div style={{textAlign: "center"}}>
            <b>Data Leakage in AI on Big Data-Medical Imaging</b> <br/>
            Dr. Farid Ghareh Mohammadi <br/>
            Mayo Clinic, Florida, USA <br />
            </div>
        
            <h6 className="par_header">Abstract:</h6>
            <div className="par">
            Data leakage is a critical but often overlooked challenge in the development of AI systems for medical imaging with profound implications for clinical accuracy. Data leakage  occurs when external information unintentionally contaminates the whole model development and evaluation process, leading to inflated performance metrics and inaccurate results. The complexity and scale of medical images amplify the difficulty of preventing leakage.The impact of data leakage can be significant, leading to biased models that overestimate performance, hinder generalization, and produce misleading insights. In this talk, I will explore the various forms of data leakage that compromise the integrity of machine learning/deep learning models. I will highlight best practices for preventing data leakage. By addressing these issues, researchers can ensure the development of trustworthy AI systems that truly generalizable, moving us closer to the safe and effective integration of AI in clinical decision-making. This talk is essential for researchers, clinicians, and developers committed to building AI systems that meet the highest standards of reliability, and safety in medical imaging.
            </div>

            <h6 className="par_header">Speaker’s Biography:</h6>
            <div className="par">
            Farid Ghareh Mohammadi, Ph.D., is an accomplished interdisciplinary Postdoctoral Research Fellow at Mayo Clinic, with a specialization in cutting-edge deep learning applications within musculoskeletal imaging. His work spans both diagnosis and authentication, combining advanced AI methodologies with medical imaging to drive innovation in healthcare. Currently, he serves as the co-investigator on a prestigious 5-year NIH R01 Grant awarded by the National Institute of Biomedical Imaging and Bioengineering in 2024. He earned his Ph.D. in Computer Science from the University of Georgia, with a concentration in meta/multi-model classification. His scholarly contributions include over 40 publications in leading international journals, books and conferences. He is the recipient of the  outstanding achievement award for Deep Learning at CSCI’19 and has been recognized as an associate editor for CSCE from 2022-2024, reflecting his commitment to advancing research in his field.
            <br/> <br/>
            <div style={{textAlign: "center"}}>
                <img src="/static/farid.png" height="300px" alt=""/>
            </div>
            </div>          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Keynote3;