import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsISED extends Component {

    componentDidMount = () => {
        console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>CSCI 2024</h1>
              <h6>The 2024 International Conference on</h6>
              <h6>Computational Science and Computational Intelligence (CSCI)</h6>
              <h6>December 11-13, 2024, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Research Track on Education (CSCI-RTED)</h4>
            <div className="par">
            All accepted papers of all Research Tracks will be published by Springer Nature (<a href="https://www.springernature.com/" target="_blank" rel="noopener noreferrer">https://www.springernature.com/</a>)  in the proceedings of The 2024 International Conference on Computational Science and Computational Intelligence (CSCI'24).
            <br/>
            Past conference proceedings can be accessed via IEEE Xplore Digital Library at: <br/> <br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822065">Volume I (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/tocresult.jsp?isnumber=6822285">Volume II (2014)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7420813">Volume (2015)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=7879541">Volume (2016)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/mostRecentIssue.jsp?punumber=8554238">Volume (2017)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/8859311/proceeding">Volume (2018)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9052554/proceeding">Volume (2019)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9457837/proceeding">Volume (2020)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/9798893/proceeding">Volume (2021)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10216364/proceeding">Volume (2022)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/10590067/proceeding">Volume (2023)</a><br/>
            <Icon icon='link' size="1x"/> <a target="_blank" href="https://ieeexplore.ieee.org/xpl/conhome/1803739/all-proceedings">All CSCI Proceedings</a><br/>

            <br/>
            Thanks to the contributions of the authors of previously published papers, we are pleased to announce that this year's publication will have significantly increased visibility in the Science Citation Index (SCI). <br /><br />
                INDEXATION: Scopus (<a href="https://scopus.com" target="_blank" rel="noopener noreferrer">scopus.com</a>), DBLP, EI Engineering Index (Compendex, Inspec databases), <a href="https://www.engineeringvillage.com" target="_blank" rel="noopener noreferrer">www.engineeringvillage.com</a>, Springer Link (<a href="https://link.springer.com/" target="_blank" rel="noopener noreferrer">https://link.springer.com/</a>), Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others.
            </div>


            <h6 className="par_header">Scope & Topics</h6>
            <div className="par">
              Topics of interest include, but are not limited to the following: <br/> <br/>

              <div>
              - ABET Accreditation and assessment (experiences and methods) <br/>
              - Student recruitment and retention methods <br/>
              - Promoting multi-disciplinary initiatives - impact on curriculum <br/>
              - Capstone research projects: examples and case studies <br/>
              - Distance learning; methods, technologies and assessment <br/>
              - Innovative degree programs and certificates <br/>
              - Innovative uses of technology in the classroom <br/>
              - Collaborative learning <br/>
              - Learning models and learning from mistakes <br/>
              - Computer and web-based software for instruction <br/>
              - Incorporating writing into CS curriculum <br/>
              - Preparing graduates for industry <br/>
              - Partnerships with industry and government <br/>
              - Team projects and case studies <br/>
              - Undergraduate research experiences <br/>
              - Student observation and mentoring strategies <br/>
              - Advising methods <br/>
              - Evaluation strategies (professors, students, ...) <br/>
              - Transition to graduate studies <br/>
              - Integrating gender and culture issues into computer science and Engineering curriculum <br/>
              - Issues related to the choice of first programming language <br/>
              - Debugging tools and learning <br/>
              - Projects, software engineering, programming issues, and laboratory practices <br/>
              - Virtual laboratories <br/>
              - Certificate Programs <br/>
              - Active learning tools <br/>
              - Funding opportunities for curriculum development and studies <br/>
              - Pilot studies <br/>
              - STEM (Science, Technology, Engineering & Mathematics) initiatives <br/>
              - Teaching methods <br/>
              - Recruiting methods to attract graduate students <br/>
              - The role of visualization and animation in education <br/>
              - Experiences in the formation of Department Advisory Board <br/>
              - Factors that lead to success in new degree programs <br/>
              - Software Engineering for Embedded Systems in Education
              </div>


            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsISED;