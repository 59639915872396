import React, { Component } from 'react';
import { Form, Button,Col, Row, Modal, ListGroup, Tabs, Tab, Table  } from 'react-bootstrap';
import country_list from './_countries'
import { push, replace } from 'react-router-redux'
import {connect} from 'react-redux';
import {alertMess, Spinner_Action} from '../../actions';
import axios from "axios";
import conf from '../../_Conf'

class Reg_Form2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            found: false,
            prev_payments: []
        }
    }

    onNext1 = () => {
        this.props.alertMess({isVis: false, message:'', error:false});
        
        this.props.replace(
            {
                pathname: '/csci2024/registration_fee_calc_add',
                state: { form: this.state.prev_payments }
            })
    }

    onSearch = () => {
        this.props.alertMess({isVis: false, message:'', error:false});
        this.props.Spinner_Action({show:true})
        let obj = {
            email: document.getElementById("reg_f_email").value,
        }
        if(obj.email == ""){
            this.props.Spinner_Action({show:false})  
            this.props.alertMess({isVis: true, message:'Please insert email', error:true}); 
            return;
        }

        axios.post(conf.Production? conf.ProductionAddress + 'csci2024/author_search/' : 'http://127.0.0.1:5050/' + 'csci2024/author_search/',
        obj,
        { headers: { 'Content-Type': 'application/json' } }
        ).then(response => {
            // console.log(response.data)
            this.setState({
                found: true,
                prev_payments: response.data
            })
            this.props.Spinner_Action({show:false})
        }).catch( err => {
          this.props.alertMess({isVis: true, message:err.response.data.errors, error:true})
          this.props.Spinner_Action({show:false})
        })
    }

    render() {
    return (
        <div className="r_container">
        <div className="home">
            <div className="new_banner">
                <div className="banner_content">
                <h1>CSCI 2024</h1>
                <h6>The 2024 International Conference on</h6>
                <h6>Computational Science and Computational Intelligence (CSCI)</h6>
                <h6>December 11-13, 2024, Las Vegas, USA</h6>
                </div>

                <div className="new_banner_overlay"></div>
            </div>

            <div className="page_content">
                <h6 className="par_header">Registration - Personal Info</h6>
                <div className="reg_form" style={{"max-width": "40rem", margin: "2rem auto"}}>
                    <Form>
                    <Row>
                        <Col sm={4}>
                            <Form.Label>Email: </Form.Label>
                        </Col>
                        <Col sm={8}>
                            <Form.Control id="reg_f_email"  />
                        </Col>
                    </Row>
                    
                    {this.state.found &&
                        <div className="confirm_everything">
                            <div>
                                <b>Name:</b> {this.state.prev_payments[0].title} {this.state.prev_payments[0].fn} {this.state.prev_payments[0].ln}
                            </div>
                            <div>
                                <b>Email: </b> {this.state.prev_payments[0].email}
                            </div>
                            <div>
                                <b>University/College/Company: </b> {this.state.prev_payments[0].uni}
                            </div>
                            <div>
                                <b>Country: </b> {this.state.prev_payments[0].country}
                            </div>
                        </div>
                    
                    }


                    <Row>
                        {this.state.found?
                            <Button onClick={this.onNext1}>
                                Next
                            </Button>:
                            <Button onClick={this.onSearch}>
                                Search
                            </Button>
                        }
                    </Row>


                </Form>
              </div>

            </div>
        
        
        </div>
        </div>

    );
  }
}

const mapStateToProps = state => {
    return {
        alertReducer: state.alertReducer,
        Spinner_ActionReducer: state.Spinner_ActionReducer,
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      alertMess,
      replace,
      Spinner_Action
    }
  }
export default connect(mapStateToProps, mapDispatchToProps())(Reg_Form2);